import React, { useState, useEffect } from "react";
import "./Services.css";
import ServiceItem from "../Pages/ServiceItem";

import arrow from "../assets/Images/Vector.png";
import tablet from "../assets/Images/Services/Tablet/tablet.png";
import makeitbetter from "../assets/Images/Services/Tablet/makeitbetter.png";
import brandsweserve from "../assets/Images/Services/BrandsWeServe.png";
import process1 from "../assets/Images/Services/Frame 407.png";
import process2 from "../assets/Images/Services/Frame 406.png";
import process3 from "../assets/Images/Services/Frame 405.png";

import screenRepair from "../assets/Images/Services/Tablet/Group 349.png";
import motherboard from "../assets/Images/Services/Tablet/Group 350.png";
import batteryReplacement from "../assets/Images/Services/Tablet/Group 351.png";
import waterdamage from "../assets/Images/Services/Tablet/Group 348.png";
import powerissues from "../assets/Images/Services/Tablet/Group 352.png";
import Servicecardcontainer from "../Components/Servicecardcontainer";

export default function Tablet() {
  const [items, setItems] = useState([
    {
      name: "Screen Repair",
      content:
        "When your tablet or iPad's screen is cracked or shattered, it's nearly impossible to use it. Fortunately, we can quickly repair tablet displays. We keep a large inventory of tablet screens on hand so that we can repair them on the spot.",
      image: screenRepair,
      price: 1999,
      quantity: 0,
    },

    {
      name: "Motherboard",
      content:
        "Is your iPad getting too hot to carry? Is it no longer alive? The motherboard of a tablet or iPad might fail for a variety of reasons. Allow the experts at UCRACKWEFIX to assist you with a quick motherboard repair.",
      image: motherboard,
      price: 599,
      quantity: 0,
    },

    {
      name: "Battery Replacement",
      content:
        "Tablet/Ipad Battery Draining fast, or not charging properly we provide genuine battery replacement with an extended warranty.",
      image: batteryReplacement,
      price: 1999,
      quantity: 0,
    },

    {
      name: "Water Damage",
      content:
        "If you accidentally dropped your tablet in water, don't worry; UCRACKWEFIX can fix any water-damaged gadget.",
      image: waterdamage,
      price: 1999,
      quantity: 0,
    },

    {
      name: "Power Issues",
      content:
        "There could be several, reasons why your iPad shuts down without warning. As a result, our professionals will examine each factor and provide a suitable strategy to resolve the problem at your location.",
      image: powerissues,
      price: 499,
      quantity: 0,
    },
  ]);

  const addtocart = (item, index, qty) => {
    console.log("adding to cart", index, qty);
    var updatedItems = [...items];
    updatedItems[index].quantity = qty;
    setItems(updatedItems);
    var cartlist = items.filter((item) => item.quantity > 0);
    localStorage.setItem("ucrackcart", JSON.stringify(cartlist));
  };
  // console.log(items)
  return (
    <div className="serviceMain tablet">
      <title>Most convenient Tablet repair near you. Fix tablet at doorstep within no time</title>
      <div className="serviceHead">
        <img src={tablet} alt="" />
        <div>
          <h1 className="heading">Tablet/Ipad</h1>
          <h6>Quality repairing at your Affordability</h6>
          <h6>Know the services</h6>
          <h6>Brands we service</h6>
          <h6>Know the Process</h6>
          <button
            onClick={() =>
              window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")
            }
            className="RepairBTN"
            style={{ marginTop: "7vh" }}
          >
            <span>Repair my device</span>
            <img src={arrow} alt="" />
          </button>
        </div>
      </div>
      <div className="makeitBetter">
        <div>
          <h1 className="heading" style={{ fontSize: "35px" }}>
            Customer Service, We Make it Better.
          </h1>
          <h6>
            Tablets have become the most popular electronic device. As a tablet
            comes in handy, having all of the capabilities of a complete
            computer is ideal. It can be upsetting to having your favorite
            gadget fail, but we want to set your mind at ease, therefore no
            matter how infrequent these breaks are, we got your back.
            <br />
            <br />
            We know everything about tablets and UCRACKWEFIX experts can fix all
            your problems. The most recent technological advancements enable us
            to repair any form of damage. We offer the greatest quality repair
            at the lowest price, whether your tablet is an iPad, Google, Amazon,
            or any other brand.
          </h6>
        </div>
        <div>
          <img src={makeitbetter} alt="" />
        </div>
      </div>
      <div className="knowtheservices">
        <h1>Know the services</h1>
        <h6>
          Having a broken tablet/IPad is too upsetting, and merely thinking
          about our gadgets malfunctioning is enough to create anxiety. The
          following are some of the most typical problems encountered by
          tablet/IPad users:
        </h6>
      </div>
      <div className="serviceItems">
        {items.map((item, index) => (
          <ServiceItem item={item} index={index} addtocart={addtocart} />
        ))}
      </div>
      <div className="brandsweserve">
        <h1>Brands We Service</h1>
        <img src={brandsweserve} alt="" />
      </div>
      <div className="knowtheprocess">
        <h1>Know the Process</h1>
        <h6>
          Are you nervous about getting your gadgets or any electronic devices
          repaired? At UCRACKWEFIX, we understand! Our network of technicians is
          background checked. We have put together a process that allows us to
          make your repair fast and convenient for you.
        </h6>
      </div>
      <div className="knowCards">
        <div className="blue"></div>
        <div className="processCard">
          <div className="processImg">
            <img src={process1} alt="" />
          </div>
          <h3>Book a Service</h3>
          <h6>
            You can select the device and service based on your preferences. If
            you need assistance placing your order, please call us at
            9017-247-247or fill out the form below by describing the service you
            require and pressing the connect button to receive a callback.
          </h6>
        </div>
        <div className="processCard">
          <div className="processImg">
            <img src={process2} alt="" />
          </div>
          <h3>Device Collection</h3>
          <h6>
            Pick up of your Tablet is FREE. If there is a minor issue Our
            executive partner will repair it at your place or else will pick up
            your device At our Lab, Our expert technicians will diagnose and fix
            your device to perfection
          </h6>
        </div>
        <div className="processCard">
          <div className="processImg">
            <img src={process3} alt="" />
          </div>
          <h3>Repair done at doorstep.</h3>
          <h6>
            We will deliver your repaired device within 2-5 days, depending on
            the location and repair type.
          </h6>
        </div>
      </div>
      <Servicecardcontainer />
    </div>
  );
}
