import React,{ useState, useEffect, useRef } from 'react'
import "./Homepage.css"

import arrow from '../assets/Images/Vector.png'
import chatbot from '../assets/Images/Chatbot.png'
import search from '../assets/Images/Search.png'
import service1 from '../assets/Images/service1.png'
import service2 from '../assets/Images/service2.png'
import service3 from '../assets/Images/service3.png'
import service4 from '../assets/Images/service4.png'
import service5 from '../assets/Images/service5.png'
import service6 from '../assets/Images/service6.png'
import service7 from '../assets/Images/service7.png'
import service8 from '../assets/Images/service8.png'
import { NavLink } from 'react-bootstrap'


export default function HomepageCarousal() {
    const [slideCount, setSlideCount] = useState(1)
    const change = useRef(0)
    useEffect(()=>{
        setInterval(() => {
            autochange()
        }, 4000);
    }, [])
    const autochange = () =>{
        if(change.current === 8){
            change.current = 1
        }else{
            change.current += 1
        }
        setSlideCount(change.current)
    }
    const slidechange = (n) =>{
        console.log('changing slide')
        change.current = slideCount
        if(n === "next"){
            if(slideCount === 8){
                setSlideCount(1)
            }else{
                setSlideCount(slideCount + 1)
            }
        }else{
            if(slideCount === 1){
                setSlideCount(8)
            }else{
                setSlideCount(slideCount - 1)
            }
        }
    }

    
 
    return (
        <div className="homepageCarousal">
            <div className="leftCaro caro">
                <h1>We Fix</h1>
                <div className="searchBox">
                    <input type="text" placeholder="Search Products" />
                    <img src={search} alt="" />
                </div>
            </div>
            <div className="leftcaroBTN caroBTN"> 
                <button onClick={()=>slidechange("prev")} ><img src={arrow} alt="" /> </button> 
            </div>
            <div className="imageCaro">
                <div></div>
                <div >
                    <img className={"caroImg "+ (slideCount===1 && "caroimgActive")} src={service1} alt="" />
                    <img className={"caroImg "+ (slideCount===2 && "caroimgActive")} src={service2} alt="" />
                    <img className={"caroImg "+ (slideCount===3 && "caroimgActive")} src={service3} alt="" />
                    <img className={"caroImg "+ (slideCount===4 && "caroimgActive")} src={service4} alt="" />
                    <img className={"caroImg "+ (slideCount===5 && "caroimgActive")} src={service5} alt="" />
                    <img className={"caroImg "+ (slideCount===6 && "caroimgActive")} src={service6} alt="" />
                    <img className={"caroImg "+ (slideCount===7 && "caroimgActive")} src={service7} alt="" />
                    <img className={"caroImg "+ (slideCount===8 && "caroimgActive")} src={service8} alt="" />
                </div>
                {slideCount === 1 && 
                <NavLink href="/mobile-repair" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 2 && 
                <NavLink href="/tablet-repair-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 3 && 
                <NavLink href="/laptop-repair-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 4 && 
                <NavLink href="/tv-repair-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 5 && 
                <NavLink href="/water-purifier-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 6 && 
                <NavLink href="/ac-repair-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 7 && 
                <NavLink href="/washing-machine-repair" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
                {slideCount === 8 && 
                <NavLink href="/cctv-installation-service" className="BookaserviceHome">
                <h6>Book a service</h6>
                <div><img src={arrow} alt="" /></div>
                </NavLink>}
            </div>
            <div className="rightcaroBTN caroBTN"> 
                <button onClick={()=>slidechange("next")} ><img src={arrow} alt="" /> </button> 
            </div>
            <div className="chatbotBTN" onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} >
                <img src={chatbot} alt="chatbot hai" />
            </div>
            <div className="rightCaro caro">
                {slideCount===1 && <h1>Mobile Phones</h1>}
                {slideCount===2 && <h1>Tablet</h1>}
                {slideCount===3 && <h1>Laptop</h1>}
                {slideCount===4 && <h1>LCD/LED</h1>}
                {slideCount===5 && <h1>Water Purifier</h1>}
                {slideCount===6 && <h1>Air Conditioner</h1>}
                {slideCount===7 && <h1>Washing Machine</h1>}
                {slideCount===8 && <h1>CCTV</h1>}
                <h5 style={{textAlign:'left'}}>The most professional place to<br/> fix your Gadgets.</h5>
            </div>
            
        </div>
    )
}
