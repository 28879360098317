import React, { useState } from 'react'
import arrow from '../../assets/Images/Vector.png'
import Applicationmodal from './Applicationmodal'

export default function Appliancecard({item}) {
    const [appModal , setAppModal] = useState(false)
    return (
        <div className="applianceMain">
            {
                appModal ? <Applicationmodal item={item} setAppModal={setAppModal}/> : ''        
            }
            <div className="applianceCard">
                <div></div>
                <div>
                    <h1>{item.Name}</h1>
                    <h1>Price <br/><span style={{fontWeight: 'bold', fontSize: '4vh'}} >₹{item.Price}</span> </h1>
                </div>
                <img src={item.AppImage} alt="" />
            </div>
            <div onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="addtocart2" onClick={()=> setAppModal(true)}>
                Book a service
                <img src={arrow} alt="" />
            </div>
        </div>
    )
}
