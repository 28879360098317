import React, {  useState, useEffect } from 'react'

import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import arrow from '../assets/Images/Vector.png'
import laptop from '../assets/Images/Services/Laptop/Laptop.png'
import makeitbetter from '../assets/Images/Services/Laptop/makeitbetter.png'
import brandsweserve from '../assets/Images/Services/Laptop/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'

import screenrepair from '../assets/Images/Services/Laptop/Frame 604.png'
import battery from '../assets/Images/Services/Laptop/Frame 605.png'
import motherborad from '../assets/Images/Services/Laptop/Group 560.png'
import heating from '../assets/Images/Services/Laptop/Frame 607.png'
import waterdamage from '../assets/Images/Services/Laptop/Group 569.png'
import power from '../assets/Images/Services/Laptop/Group 541.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'


export default function Laptop() {
    const [items, setItems] = useState([
        {name: 'Screen Repair', content: 'If you can see your laptop turning on but cannot see the display coming up or have a broken screen, you can book a case for a laptop screen repair service with us.', image: screenrepair, price: 1999, quantity: 0},

        {name: 'Battery Replacement', content: "Your laptop's battery may not charge for one of two reasons:  it is faulty or dead, or (ii) your charger or adapter is faulty. In any situation, you may need to seek professional assistance in determining the root source of the problem.", image: battery, price: 1999, quantity: 0},

        {name: 'MotherBoard', content: "Because of its complexity, repairing a motherboard at home is challenging. Whatever the problem is with your laptop's motherboard, you can call UCRACKWEFIX experts, who can diagnose and solve the problem.", image: motherborad, price: 2999, quantity: 0},

        {name: 'Heating Issue', content: "It's critical to deal with an overheating laptop. There are several possible causes for an overheating laptop, and these can be resolved by our experts in no time.", image: heating, price: 500, quantity: 0},

        {name: 'Power Issue', content: 'If your laptop is not starting at all power issue can be the reason, try contacting UCRACKWEFIX so that your work doesn’t suffer because of your laptop issues.', image: power, price: 500, quantity: 0},

        {name: 'Water Damage', content: "Water damages and disorganizes the internal components of your laptop. Expert specialists from UCRACKWEFIX will assess each aspect and provide a viable solution to the problem right at your home.", image: waterdamage, price: 1999, quantity: 0},
    ])

    
    
     const addtocart = (item,index, qty) =>{
         console.log('adding to cart', index, qty)
         var updatedItems = [...items]
         updatedItems[index].quantity = qty
         setItems(updatedItems)
         var cartlist = items.filter((item)=>item.quantity>0)
         localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
     }
    // console.log(items)
    return (
        <div className="serviceMain Laptop">
            <title>Perfect choice for laptop servicing online at doorstep with extended warranty</title>
            <div className="serviceHead">
                <img src={laptop} alt="" />
                <div>
                    <h1 className="heading">Laptop</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>We at UCRACKWEFIX strive for excellent customer service and provide high-quality professional services at a reasonable cost. UCRACKWEFIX has a pan-India network of qualified and verified technicians. They can address a variety of problems with your laptop, such as not starting, producing noises, getting hot, or performing poorly. We offer solutions for remote access support, such as security software installations and hardware repair services. We do all kinds of Laptop repair you might need.</h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>You may experience laptop problems for several reasons, and our repair service covers the full spectrum of repairs, including:
                </h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map(
                            (item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you.
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>The device and service can be chosen based on your preferences. In case you need help placing the order, just contact us at 9017-247-247or fill in the form by telling us what service you want, and click on the connect button for a callback.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>Pick up of your Laptop is FREE. If there is a minor issue Our executive partner will repair it at your place or else will pick up your device at our Lab. Our expert technicians will diagnose and fix your device to perfection.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>We will deliver your repaired device within 2-5 days, depending on the location and repair type.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
