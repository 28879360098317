import React, { useState, useEffect, useRef } from 'react';
import Checkoutitem from '../Components/Checkoutitem';
import './Checkout.css';

import fridge from '../assets/Images/Items/Fridge.png';
import lcd from '../assets/Images/Items/LCD.png';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import Logo from '../assets/Images/logoicon.png';
import ThankYou from '../Components/ThankYou';

export default function Checkout() {
  // const thankYou = useRef(false)
  const [thankyou, setThankyou] = useState(false);

  const [itemList, setItemList] = useState([]);

  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [address, setAddress] = useState('');
  const [pin, setPin] = useState('');
  const [city, setCity] = useState('');
  const [provience, setProvience] = useState('');
  const [progress, setProgess] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  let history = useHistory();

  const checkpayment = () => {
    setProgess(progress + 1);
    // console.log(phone)
    if (progress > 1) {
      console.log('payment done');
      displayRazorpay();
    } else {
      console.log('Do the payment first');
    }
  };
  const createorder = () => {
    console.log('calling the function');
    axios
      .post(
        'https://us-central1-ucrackwefix-f8a89.cloudfunctions.net/create_order',
        {
          phone: phone,
          name: name,
          cart: itemList,
          address: address + city + provience,
          pincode: pin,
          totalAmount: totalAmount * 1.18,
          paymentStatus: true,
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.message == 'Order created successfully') {
          console.log('success');
          //clear cart

          // show thankyou and move to homescreen
          setThankyou(true);
          setTimeout(() => {
            history.push('/');
          }, 5000);
        }
      });
  };

  const clearCart = () => {
    // localStorage.removeItem('ucrackcart')
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const displayRazorpay = async () => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay failed . Please try again');
      return;
    }
    let order_id = Math.floor(Math.random() * 1000000) + 1;
    var options = {
      key: 'rzp_test_3dGwkYffRlAIH0',
      amount: totalAmount * 1.18 * 100,
      currency: 'INR',
      name: 'U Crack We Fix',
      description: 'Payment for order',
      image: Logo,
      // "order_id": order_id,
      handler: function (response) {
        console.log('payment id', response);
        createorder();
        clearCart();
        // It comes here after success so you can add the api call here.
        // Google razorpay webhook for captured payment that is what we need to setup for complete validation. Let's talk tmr
      },
      prefill: {
        name: name,
        email: mail,
        contact: phone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <>
      {/* <ThankYou/> */}
      {thankyou ? <ThankYou /> : ''}
      <div className='checkoutMain'>
        <div className='checkoutContainer'>
          <div className='progressbar'>
            <div
              className={
                'progressPoints ' + (progress === 1 && 'progressActive')
              }
              onClick={() => setProgess(1)}
            >
              1
            </div>
            <div
              className={
                'progressPoints ' + (progress === 2 && 'progressActive')
              }
              onClick={() => setProgess(2)}
            >
              2
            </div>
            <div
              className={
                'progressPoints ' + (progress === 3 && 'progressActive')
              }
              onClick={() => setProgess(3)}
            >
              3
            </div>
            <div className='progessline'></div>
          </div>
          {progress === 1 ? (
            <>
              <h3 className='checkoutHead'>Checkout</h3>
              <div className='checkoutItems'>
                {itemList.map((item) => (
                  <Checkoutitem item={item} />
                ))}
              </div>
            </>
          ) : (
            ''
          )}
          {progress === 2 ? (
            <div className='checkoutForm'>
              <div className='checkoutSidebar' />
              <div className='checkoutFormContainer'>
                <input
                  type='text'
                  placeholder='Full Name'
                  onChange={(e) => setName(e.target.value)}
                />
                <input
                  type='number'
                  placeholder='Phone Number'
                  onChange={(e) => setPhone(e.target.value)}
                />
                <input
                  type='text'
                  placeholder='Email Address'
                  onChange={(e) => setMail(e.target.value)}
                />
                <input
                  type='text'
                  placeholder='Address'
                  onChange={(e) => setAddress(e.target.value)}
                />
                <div>
                  <input
                    type='number'
                    placeholder='Pincode'
                    onChange={(e) => setPin(e.target.value)}
                  />
                  <input
                    type='text'
                    placeholder='City'
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>
                <input
                  type='text'
                  placeholder='State'
                  onChange={(e) => setProvience(e.target.value)}
                />
                <div>
                  <input type='checkbox' checked={true} />I agree to the terms
                  and conditions
                </div>
                {/* <div>
                            <input type="checkbox"/>
                            I wish to recieve notifications
                        </div> */}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='checkoutAmount'>
          <h3>Total Amount</h3>
          <h4>
            Amount <span>₹{totalAmount}</span>{' '}
          </h4>
          <h4>
            GST <span>₹{parseInt(totalAmount * 0.18)}</span>{' '}
          </h4>
          <h4 className='finalamount'>
            Final Amount <span>₹{parseInt(totalAmount * 1.18)}</span>{' '}
          </h4>
          {/* <div className="couponInput">
                    <input type="text" placeholder="Have a Coupon Code?" />
                    <h6>Apply</h6>
                </div> */}
          <button onClick={() => checkpayment()}>
            {progress > 1 ? 'Pay Now' : 'Proceed to checkout'}
          </button>
        </div>
      </div>
    </>
  );
}
