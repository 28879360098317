import React from 'react'
import './Allblogs.css'

import coffee from '../assets/Images/BlogImages/makebettercoffee.png'
import allblog1 from '../assets/Images/BlogImages/allblog1.png'
import { Container, makeStyles, Typography , Paper } from '@material-ui/core'
import CarouselCard from 'react-multi-carousel';
import { Nav, NavLink } from 'react-bootstrap';
import { primaryColor, primaryLightColor, secondaryColor, testcolor, whiteColor } from '../assets/constants/constants';

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import BlogImage1 from "../assets/Images/BlogImages/blog1.jpeg"
import BlogImage2 from "../assets/Images/BlogImages/blog2.jpg"
import BlogImage3 from "../assets/Images/BlogImages/blog3.jpg"
import BlogImage4 from "../assets/Images/BlogImages/blog4.jpg"
import whatisit from "../assets/Images/BlogImages/whatisit.png"

const shadowColorHex = "rgba(0, 0, 0, 0.52)"

const useStyles = makeStyles((theme) => ({
    blogcard:{
        margin:"50px 0px",
        borderRadius:30,
        backgroundSize:'100% 100%',
        backgroundRepeat:'no-repeat',
        backgroundPosition: 'center',
        height:'400px',
        width:'90%',
        fontFamily: 'Lato',

        marginLeft:'5%'
    },
    blogimage:{
        width:'100%',
        height:200,
        objectFit:'cover',
        borderTopLeftRadius:30,
        borderTopRightRadius:30,
        fontFamily: 'Lato',

    },
    blogtitle:{
        fontSize:20,
        fontWeight:600,
        padding:'20px 20px',
        textAlign:'start',
        fontFamily: 'Lato',

    },
    blogdesc:{
        maxHeight:80,
        textAlign:'left',
        marginLeft:30,
        marginRight: 30
    },
    blogtime:{
        fontSize:12,
        padding:'20px 20px',
        textAlign:'start',
        color:'#8c8c8c',
        fontWeight:600,
        fontFamily: 'Lato',

    },
    arrowiconcontainer:{
        padding:6,
        backgroundColor:"#c7c5c5",
        borderTopRightRadius:20,
        borderBottomRightRadius:20,
        borderRadius:20,
        transition: "0.5s ease",
        fontFamily: 'Lato',


    },
    arrowicon:{
        color:'#f7f7f7',
        fontSize:30,
        fontFamily: 'Lato',

    },
    serviceimagecontainer:{
        display:'flex',
        justifyContent:'center',
        fontFamily: 'Lato',

    },
    buttonContainerblog:{
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:primaryColor,
        width:200,
        borderRadius:20,
        fontFamily: 'Lato',
        margin:"10px 0px",
        cursor:'pointer',
        position:'absolute',
        bottom:20,
        transition: "0.5s ease",
        boxShadow: `2px 5px 10px ₹{shadowColorHex}`,
        "&:hover":{
            boxShadow: `5px 10px 15px ₹{shadowColorHex}`,
        }
    },
    buttontext:{
        textAlign:'center',
        color:whiteColor,
        padding:10,
        fontSize:16,
        width:'90%',
        transition: "0.5s",
        fontFamily: 'Lato',

    },
}))
export default function Allblogs() {
    const classes = useStyles()
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    return (
        <div className="allblogsMain">
            <div>
                <div>
                    <h1>Make Better Coffee</h1>
                    <h6>Why learn how to blog?</h6>
                </div>
                <img src={coffee} alt="" />
            </div>           
            <div>
                <div>
                    <h2>Lorem ipsum</h2>
                    <h6>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque aut optio rem provident dicta quis ab reprehenderit, similique doloremque. Incidunt voluptatem enim molestiae, voluptates deserunt corporis recusandae saepe iure asperiores autem.</h6>
                </div>
                <img src={allblog1} alt="" />
            </div> 
            <Container className="blogCarousal">
                <CarouselCard
                responsive={responsive}
                autoPlaySpeed={3000}
                arrows={true}
                infinite={true}
                autoPlay={true}
                style={{margin:"20px 0px"}}
                >
                        <Paper className={classes.blogcard} >
                            <img src={BlogImage1} alt="" className={classes.blogimage} />
                            <Typography className={classes.blogtitle}>
                            Repair and Installation of Air Conditioners in Delhi NCR by UCRACKWEFIX
                            </Typography>
                            <Typography className={classes.blogdesc}> In the 21st century, air conditioners are the most essential appliance at home,</Typography>
                            <Typography className={classes.blogtime}> </Typography>
                            <div className={classes.serviceimagecontainer}>                            
                            <div className={classes.buttonContainerblog}  >
                            <Nav.Link href="/blog1" className={classes.buttontext}>
                                Take A look
                            </Nav.Link>
                            <div className={classes.arrowiconcontainer}>
                            <ArrowRightAltIcon className={classes.arrowicon} />
                            </div>
                             </div>
                        </div>
                        </Paper>
                        <Paper className={classes.blogcard} >
                            <img src={BlogImage2} alt="" className={classes.blogimage} />
                            <Typography className={classes.blogtitle}>
                            LED TVs: Common Problems To Be Aware Of
                            </Typography>
                            <Typography className={classes.blogdesc}>Indian homes have become increasingly equipped with televisions.</Typography>
                            <Typography className={classes.blogtime}> </Typography>
                            <div className={classes.serviceimagecontainer}>                            
                            <div className={classes.buttonContainerblog}  >
                            <Nav.Link href="/blog2" className={classes.buttontext}>
                                Take A look
                            </Nav.Link>
                            <div className={classes.arrowiconcontainer}>
                            <ArrowRightAltIcon className={classes.arrowicon}/>
                            </div>
                             </div>
                        </div>
                        </Paper>
                        <Paper className={classes.blogcard} >
                            <img src={BlogImage3} alt="" className={classes.blogimage} />
                            <Typography className={classes.blogtitle}>
                            Mobile Repairs At Your Doorstep
                            </Typography>
                            <Typography className={classes.blogdesc}>Does your mobile phone have a cracked screen? Are you concerned</Typography>
                            <Typography className={classes.blogtime}> </Typography>
                            <div className={classes.serviceimagecontainer}>                            
                            <div className={classes.buttonContainerblog} >
                            <Nav.Link href="/blog3" className={classes.buttontext} >
                                Take A look
                            </Nav.Link>
                            <div className={classes.arrowiconcontainer}>
                            <ArrowRightAltIcon className={classes.arrowicon}/>
                            </div>
                             </div>
                        </div>
                        </Paper>
                        <Paper className={classes.blogcard} >
                            <img src={BlogImage4} alt="" className={classes.blogimage} />
                            <Typography className={classes.blogtitle}>
                            The Best Way To Service Your RO Water System 
                            </Typography>
                            <Typography className={classes.blogdesc}>The availability of clean drinking water is crucial to health. Most of the cities </Typography>
                            <Typography className={classes.blogtime}> </Typography>
                            <div className={classes.serviceimagecontainer}>                            
                            <div className={classes.buttonContainerblog} >
                            <Nav.Link href="/blog4" className={classes.buttontext} >
                                Take A look
                            </Nav.Link>
                            <div className={classes.arrowiconcontainer}>
                            <ArrowRightAltIcon className={classes.arrowicon}/>
                            </div>
                             </div>
                        </div>
                        </Paper>
                        
                </CarouselCard>
            </Container>
            <div>
                <div>
                    <h2>What is lorem ipsum?</h2>
                    <h5>Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero voluptas, reprehenderit dolorem corrupti molestias aperiam quaerat, quod unde ipsa, debitis quo numquam ut pariatur dolor voluptatem illum nobis. Enim, minima!</h5>
                </div>
                <img src={whatisit} alt="" />
            </div>
        </div>
    )
}
