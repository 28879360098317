import React from 'react';

export default function Policy({ policyNo }) {
  return (
    <div className='policy'>
      {policyNo === 1 ? (
        <>
        <title>Privacy Policy</title>
          <h1 className='policyHeading'>Privacy Policy</h1>

          <h6 className='policyContent'>
            UCrackWeFix, henceforth known as “us”, “our”, and “we”, lays down
            the policies that safeguard personal information of the client,
            hereafter known as “you” and “your”. The rules and regulations are
            applicable for our website www.UCrackWeFix.com, mobile apps,
            electronic services, social networking portals, and all online
            services, including every online activity we run, or own.
          </h6>
          <h6 className='policyContent'>
            By using the site UCrackWeFix.com, you agree to our Privacy Policy.
            If at any point of time, you feel that you do not agree with the
            terms mentioned here, do not share your personal information. To
            know more, go through our Terms and Conditions.
          </h6>
          <h6 className='policyContent'>
            Please note, we may change our Privacy Policy. We will inform you
            about any such step before the change becomes effective. We also do
            not store your credit card or debit card details.
          </h6>
          <h6 className='policyContent'>
            Your decision to register on UCrackWeFix.com is voluntary. We do not
            share your personal details unless of course you put in a screen
            replacement request. If you have queries, please contact us at
            support@ucrackwefix.com
          </h6>
          <h3 className='policySubHead'>
            Your information with UCrackWeFix.com
          </h3>
          <h6 className='policyContent'>
            We collect the following information: IP addresses, browser details,
            OS, type of device you are using, queries made on our website, visit
            timeline, and related "Site Visitation Data". You may receive
            relevant marketing messages through your email collected from the
            Site Visitation Data. You can visit our site anonymously. However,
            we will need your details, such as name, email, postal address and
            phone number if you register with our site. We use cookies for a
            better web experience.
          </h6>
          <h3 className='policySubHead'>
            Information sharing in public forums
          </h3>
          <h6 className='policyContent'>
            Please share your information on UCrackWeFix.com responsibly with
            your family or friends. We will not be responsible for any misuse of
            such data that your friends share in public forums. Your comments
            and username will be public if you post comments on our
            blogs/forums.
          </h6>
          <h3 className='policySubHead'>Use of personal information</h3>
          <h6 className='policyContent'>
            UCrackWeFix will never pass on your personal information to third
            parties without your permission.
          </h6>
          <h6 className='policyContent'>
            There are certain exceptions to this:
          </h6>
          <ol>
            <li className='policyContent'>
              Accepting a referral from UCrackWeFix will mean that we can share
              your information with our partner sites, so you don’t have to
              re-enter your details.
            </li>
            <li className='policyContent'>
              We may share your personal information with parties helping us in
              filling forms for buying your items, or simply sending
              confirmation mails. Such parties are authorized to use your
              personal information only to help us carry out such activities.
            </li>
            <li className='policyContent'>
              We may share your personal information with third party partners
              to offer you items or services related to your interests. If you
              do not wish to receive such offers, please unsubscribe from the
              mailing lists. In case you revert to such a mail, your personal
              details will be subject to that third party's privacy policy.
            </li>
            <li className='policyContent'>
              UCrackWeFix will share your personal information in circumstances
              driven by law, subpoenas, or court orders, and to protect and
              defend the rights and interests of UCrackWeFix.com, our
              affiliates, suppliers, or users.
            </li>
            <li className='policyContent'>
              In case there is a business merger, or a takeover by another
              company, or selling a portion of UCrackWeFix.com assets, your
              personal information will become part of the assets.
            </li>
          </ol>
          <h6 className='policyContent'>
            To send customized advertisements to you, UCrackWeFix and our third
            parties may record your total usage pattern.This includes usage
            habits, preferences, and history, without disclosing your personal
            information. Registering yourself on UCrackWeFix means that you have
            allowed us to send you communication in various ways, inclusive of
            but not limited to SMSes, emails, app notifications, and any other
            means that could be beneficial to you.
          </h6>
          <h6 className='policyContent'>
            In case you do not wish to receive such communication from us, you
            can "opt out" by writing an email to support@ucrackwefix.com or by
            clicking the “unsubscribe” button on the email received by you.
          </h6>
          <h3 className='policySubHead'>Control of personal information</h3>
          <h6 className='policyContent'>
            If you wish to confirm, alter, delete, or update your personal
            information shared with us, email us at support@ucrackwefix.com.
            Opting out of promotional communication doesn’t stop our service
            messages, legally mandated notices, and third party marketing
            messages. After removing information from your profile or deleting
            your account on UCrackWeFix.com, copies of that information may
            still be available on some other site, wherein someone had shared it
            either in compliance with your privacy policy or was stored by other
            users. Your information is stored with us till the time your account
            is active or is required to provide our services, and to comply with
            our legal obligations, resolve disputes, and enforce our agreements.
          </h6>
          <h3 className='policySubHead'>Security of personal information</h3>
          <h6 className='policyContent'>
            We adopt standard procedures to safeguard your personal information.
            However, we can’t guarantee 100% security when the information
            transmission happens via the Internet. If you have any queries about
            security on UCrackWeFix.com, please email us at
            support@ucrackwefix.com
          </h6>
          <h3 className='policySubHead'>Protection of children's privacy</h3>
          <h6 className='policyContent'>
            Our website is aimed at the general audience, and not meant for
            children below the age of 13 years. While using UCrackWeFix.com, you
            understand and agree that you are 18 years or above. We do not
            deliberately gather any PII from users under the age of 13. We will
            delete information about an account or a registered user who is
            below 18 years.
          </h6>
          <h3 className='policySubHead'>
            Use of cookies and tracking technology
          </h3>
          <h6 className='policyContent'>
            We use cookies to optimize your internet experience. Cookies analyze
            a website’s or mobile application’s performance by collecting
            information about user preferences, and recall if a user has visited
            a website earlier to send relevant promotions. Cookies do not use
            your personal information.
          </h6>
          <h6 className='policyContent'>
            We may use web beacons, clear GIF technology, or action tags to
            collect data on your website visits. Tracking technology may also be
            used to check delivery of HTML email messages. Such data do not
            contain personal information.
          </h6>
          <h6 className='policyContent'>
            Permanent cookie files are stored in your device’s hard drive. You
            can delete them manually. If you do not want cookies collecting your
            data, disable cookies. However, if you turn your settings for
            cookies off, some of our services may not function.
          </h6>
          <h6 className='policyContent'>
            UCrackWeFix has third party advertising partners who may use a
            cookie to track. We don’t send PII to our advertising partners.
            Please note that the use of cookies by our partners, tracking
            utility firms, service providers, and affiliates is not covered by
            our privacy policy and have no control over such cookies.
          </h6>
          <h3 className='policySubHead'>Social Media Features and Widgets</h3>
          <h6 className='policyContent'>
            Interacting on our Social Media pages may enable the widgets to
            collect your IP address, and your site visit patterns. Your
            interactions with these social media features fall under the privacy
            policy of the company hosting these features.
          </h6>
          <h3 className='policySubHead'>Import Contacts</h3>
          <h6 className='policyContent'>
            You can import contacts from your email’s address books. Please
            note, UCrackWeFix.com will collect the user ID and password for the
            email from which you import your contacts. We will not use it for
            any other purpose.
          </h6>
          <h3 className='policySubHead'>Changes to this policy</h3>
          <h6 className='policyContent'>
            We may update our Privacy Policy. In case such a change is made, we
            will put it in the "last updated" date list. For any major changes
            to this policy, we will put a prominent notice on our home page,
            telling users before the change takes place. Your use of the
            UCrackWeFix.com site after an updated privacy policy is in place,
            will automatically mean that you have accepted the changed policy.
          </h6>
          <h3 className='policySubHead'>Users from outside India</h3>
          <h6 className='policyContent'>
            UCrackWeFix, with its location in India, is subject to Indian laws.
          </h6>
        </>
      ) : (
        ''
      )}
      {policyNo === 2 ? (
        <>
        <title>T&Cs</title>
          <h1 className='policyHeading'>Terms and Conditions</h1>
          <ol>
            <li className='policyContent'>
              The repairing of your gadget(s) after collection will take at most
              24 working hours. It may take longer, depending on the
              availability of parts and other accessories/services.
            </li>
            <li className='policyContent'>
              If a gadget requires a component replacement, the customers have
              to bear the cost of the component. Our customer service team will
              call you exclusively to take your approval before we replace any
              components.
            </li>
            <li className='policyContent'>
              In case the gadget is given in a completely non-functional
              condition with no power/no display, we’ll revert with the exact
              diagnosis and the list of the parts gone bad after a thorough
              check-up. We follow a decision tree approach to repair a problem
              and it could be quite possible that we may contact you more than
              once to get your revised approval of additional parts.
            </li>
            <li className='policyContent'>
              We charge a diagnosis/handling fee of Rs 200, in case the customer
              requests for the return of the device after refusal of the repair
              and its cost. In case a customer has provided the approval but we
              are unable to repair or source the part, we return the gadget
              without charging any handling fee.
            </li>
            <li className='policyContent'>
              The company will take responsibility of a repaired gadget for 7
              days after it is fixed if the delivery is not claimed by the
              customer. Following that, the company will not be responsible for
              any loss or damage to the gadget.
            </li>
            <li className='policyContent'>
              We reserve the right at our sole discretion to take back an old
              component in lieu of the new replaced component.
            </li>
            <li className='policyContent'>
              Customers have to pay a consultation charge of Rs 200 during our
              warranty period, as dictated by the company policy.
            </li>
            <li className='policyContent'>
              UCrackWeFix requests all its customers to fully ensure and sign
              the quality checklist before giving/taking handover of their
              device at the time of pickup or drop. Any problems unrelated to
              the repair will not be our responsibility, post the customer’s
              acceptance of the repaired device.
            </li>
            <li className='policyContent'>
              Warranty will be given only for replaced and repaired parts.
            </li>
            <li className='policyContent'>
              Kindly backup all your data and remove the SIM & memory card
              before giving your device to our executive.
            </li>
            <li className='policyContent'>
              Physical damage caused due to negligence or by accident in the new
              component replaced by UCrackWeFix will not be considered under the
              warranty. You’ll be provided another component only in the case of
              a manufacturing defect in the new component.
            </li>
            <li className='policyContent'>
              A Standby gadget will be provided by UCrackWeFix till the repairs
              are completed. After the gadget is fixed, if the customer does not
              claim his gadget, he/she has to pay Rs 100 every day as a rental
              for the standby gadget.
            </li>
            <li className='policyContent'>
              Promotional offers are subject to availability.
            </li>
            <li className='policyContent'>
              Images of complimentary products on offer are for illustrative
              purposes only. They may differ from the actual product.
            </li>
            <li className='policyContent'>
              Offer may not be combined with any other sale, promotion,
              discount, code, coupon and/or offer.
            </li>
            <li className='policyContent'>
              Promotions have no cash value. Offer cannot be sold or otherwise
              bartered.
            </li>
          </ol>
        </>
      ) : (
        ''
      )}
      {policyNo === 3 ? (
        <>
        <title>Warranty-Policy</title>
          <h1 className='policyHeading'>Warranty Policy</h1>
          <h3 className='policySubHead'>
            UCrackWeFix offers a 6-month warranty on the replaced screen under
            these conditions:
          </h3>
          <ul>
            <li className='policyContent'>
              UCrackWeFix has the right to refuse service on any claims solely
              at its own discretion.
            </li>
            <li className='policyContent'>
              The warranty includes factory screen defects, such as unresponsive
              or erratic touch.
            </li>
            <li className='policyContent'>
              The 6-month warranty is applicable only on the mobile screen
              replacement featuring the same benchmarks and capabilities of the
              original faulty screen.
            </li>
            <li className='policyContent'>
              The warranty does not cover any other parts of the mobile.
            </li>
            <li className='policyContent'>
              The warranty only covers manufacture defects, and does not
              encompass damage due to manual intervention.
            </li>
            <li className='policyContent'>
              UCrackWeFix provides 3 months warranty except screen replacement.
            </li>
          </ul>
          <h3 className='policySubHead'>
            The 6-month warranty is NOT valid under these conditions:
          </h3>
          <ul>
            <li className='policyContent'>
              Any kind of display issue will not be consider in warranty.
            </li>
            {/* <li className='policyContent'>
              Any display issues that may arise without any manual intervention
              and are related to the screen quality specifically visible lines
              and the blank screen.
            </li> */}
            <li className='policyContent'>
              If you don't hand over the non-working mobile part to the
              UCrackWeFix technician, the warranty will be valid only for 3
              months.
            </li>
            <li className='policyContent'>
              In case other components of the mobile are damaged, and the phone
              refuses to turn on and perform all the tasks it is supposed to do.
            </li>
            <li className='policyContent'>
              If the phone is left partially working, following an unsuccessful
              repair attempt at home or by any other vendor.
            </li>
            <li className='policyContent'>
              In case a phone model is known to have a particular
              manufacturing/performance issue that impairs the functioning of
              the screen.
            </li>
            <li className='policyContent'>
              In case the mobile’s frame is damaged.
            </li>
            <li className='policyContent'>
              In case the mobile is jail-broken.
            </li>
            <li className='policyContent'>
              If the internal hardware is tampered with, especially through a
              self-repair attempt.
            </li>
            <li className='policyContent'>
              The warranty will not cover phones that have been damaged
              negligently and intentionally.
            </li>
            <li className='policyContent'>
              It is also not applicable to devices that have been repaired or
              tampered-with before, by anyone else other than UCrackWeFix.
            </li>
            <li className='policyContent'>
              In case the device is transferred or sold to another user. The
              second-hand user will not be entitled to the warranty.
            </li>
            <li className='policyContent'>
              Warranty on Battery is NOT valid in case of excessive charging,
              battery swell, overnight charging or use of non-OEM Charging
              Cable/Adapters
            </li>
            <li className='policyContent'>
              Subsequent mishandling which causes the frame to bend, twist or
              crack will not be entertained.
            </li>
            <li className='policyContent'>
              Subsequent mishandling with the screen such as hard press that may
              initiate discoloring or lining on the display.
            </li>
          </ul>
          <h3 className='policySubHead'>
            To claim the 6-month warranty, all you have to do is:
          </h3>
          <ul>
            <li className='policyContent'>
              Ask the repair expert fixing your broken phone screen to avail the
              warranty. After providing the necessary details, you’ll receive
              the warranty if all the prerequisites are fulfilled.
            </li>
            <li className='policyContent'>
              Once you have claimed the warranty, the UCrackWeFix team will take
              about 48-72 hours to resolve your issue. However, it may take
              more, depending on the nature of your case.
            </li>
            <li className='policyContent'>
              Share the video of the phone with the prevailing display issue at
              support@ucrackwefix.com
            </li>
            <li className='policyContent'>
              Send us your phone number/order number/IMEI Number. Just about any
              of it at support@ucrackwefix.com
            </li>
          </ul>
          <h6 className='policyContent'>
            <span style={{ fontWeight: 'bold', fontStyle: 'normal' }}>
              Important Note:
            </span>{' '}
            In order to prevent any loss of data occurring as a result of the
            repair, customers are advised to back up all data prior to the
            repair attempt.
          </h6>
        </>
      ) : (
        ''
      )}
    </div>
  );
}
