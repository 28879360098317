import React from 'react'
import arrow from '../../assets/Images/Vector.png'

export default function Modelcard({item}) {
    return (
        <div className="modelCard">
            <div></div>
            <div>
                <img src={item.ModImage} alt="" />
            </div>
            <div>
                <h1>{item.Name}</h1>
                <h6>{item.Content} </h6>
                <h6>Frequency: <span>{item.Freq}</span></h6>
                <h6>Tenure: <span>1 year</span></h6>
            </div>
            <div>
                <h1>₹{item.Price}</h1>
                <section onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="addtocart3">
                   Book a service
                    <img src={arrow} alt="" />
                </section>
            </div>
        </div>
    )
}
