import React, { useState } from 'react'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export default function Checkoutitem({item}) {
    const [itemCount, setItemCount] = useState(item.quantity)
    return (
        <div className="checkoutitemMain">
            <img src={item.image} alt="" />
            <div>
                <h2>{item.name}</h2>
            </div>
            <div>
                <h5></h5>
                <h5></h5>
            </div>
            <div>
                <RemoveCircleOutlineIcon onClick={()=>setItemCount(itemCount-1)} />
                <h5>{itemCount}</h5>
                <AddCircleOutlineIcon onClick={()=>setItemCount(itemCount+1)}/>
            </div>
            <div>
                <h5>₹{item.price*itemCount}</h5>
                {/* <h5>₹{item.price2}</h5> */}
            </div>
        </div>
    )
}
