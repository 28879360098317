import React from 'react'
import PurchaseItem from './PurchaseItem'

export default function PurchaseContainer({items}) {
    return (
        <div className="purchaseContainer">
            <div>
                <h5>Order ID : {items.OrderID}</h5>
                <h6>Date : {items.OrderDate}</h6>
            </div>
            <div>
                {
                    items.orderItems.map((item)=>(
                        <PurchaseItem item={item}/>
                    ))
                }
            </div>
            <div>
                Total Price : <span>₹{items.TotalPrice}</span>
            </div>
        </div>
    )
}
