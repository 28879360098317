import React from 'react';
import vector from '../assets/Images/Vector.png';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CartItem from './CartItem';
import cartempty from '../assets/Images/cartEmpty.png';
// import { Link } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
export default function Cart({ setCartprof }) {
  const cartlist = JSON.parse(localStorage.getItem('ucrackcart')) || [];
  let totalPrice = 0;
  let totalItems = 0;

  cartlist.map((item) => {
    totalPrice = totalPrice + item.price * item.quantity;
    totalItems = totalItems + 1;
  });

  return (
    <div className='cartMain'>
      <div className={'cartContainer '}>
        <div>
          <h5>Cart</h5>
          <div onClick={() => setCartprof(0)}>
            <HighlightOffIcon className='close' />
          </div>
        </div>
        <div
          className='items'
          style={{ paddingTop: totalItems > 4 ? '20vh' : '4vh' }}
        >
          {cartlist.length === 0 && (
            <img
              src={cartempty}
              style={{ width: '70%', marginBottom: '5vh' }}
            />
          )}
          {cartlist.map((item) => (
            // console.log("hi")
            <CartItem item={item} />
          ))}
        </div>
        <div className='checkoutContainer'>
          <div>
            <h6>Total</h6>
            <h4>₹{totalPrice}</h4>
          </div>
          <NavLink className='checkoutBTN' href='/checkout'>
            <span>Checkout</span>
            <img src={vector} alt='' />
          </NavLink>
        </div>
      </div>
    </div>
  );
}
