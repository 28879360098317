import React, { useEffect, useState,useCallback } from "react";
import "./RefurbishedMenu.css";
import Razorpay from 'razorpay';
import arrow from "../../assets/Images/RefurbishedPhones/arrowBlue.png";
import defaultImG from "../../assets/Images/defaultMobile.png";
import { Link } from "react-router-dom";
import axios from 'axios';

export default function RefurbishedItem({ item, index }) {


  let priceOnDisplay = 0;
  let categoryOnDisplay = "A";
  if (item.PriceA) {
    priceOnDisplay = item.PriceA;
    categoryOnDisplay = "A";
  } else if (item.PriceB) {
    priceOnDisplay = item.PriceB;
    categoryOnDisplay = "B";
  } else if (item.PriceC) {
    priceOnDisplay = item.PriceC;
    categoryOnDisplay = "C";
  }

  let save = (100 - (priceOnDisplay / item.MRP) * 100).toFixed(1);

  const handlePayment = useCallback(async() => {
    const result = await axios.post("https://secure-tundra-08092.herokuapp.com/https://razerpay.herokuapp.com/payment/orders/"+item.Model+"/"+priceOnDisplay);

    if (!result) {
        alert("Server error. Are you online?");
        return;
    }

    // Getting the order details back
    const { amount, id: order_id, currency } = result.data;

    console.log(result)
    const options = {
      key_id: "rzp_test_VVaVR1T9Qy6sNv",
      amount: amount,
      currency: currency,
      name: "Ucrackwefix",
      description: item.Model,
      image: item.IMG1,
      order_id: order_id,
      handler: async function (response) {
        const data = {
            orderCreationId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
        };

        const result = await axios.post("https://secure-tundra-08092.herokuapp.com/https://razerpay.herokuapp.com/payment/success", data);
        console.log(result)
        alert(result.data.msg);
    },
      prefill: {
        name: "Piyush Garg",
        email: "piyushgarg.dev@gmail.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new window.Razorpay(options);
rzp1.open();
  }, [Razorpay]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  let words = item.Model.split(" ");
  // let priceNums = String(item.MRP).split('');
  let uniqueCode =
    words[0][0].toUpperCase() +
    words[1][0].toUpperCase() +
    index +
    categoryOnDisplay;

  const [imageNo, setImageNo] = useState(1);
  return (
    <div className="RefurbishedItemMain">
      <title>{item.title}</title>
      <div className="itemHeading">
        <h1>Smartphones </h1>
        <h5>
          Home /
          <Link className="toSmartphones" to="/refurbished-phones">
            Smartphones
          </Link>
          /{item.Model}
        </h5>
      </div>
      <div className="itemContent">
        <div className="imageConatiner">
          <div className="imageDisplay">
            {imageNo === 1 && (
              <img
                src={item.IMG1 ? item.IMG1 : defaultImG}
                alt="Phone Image1"
                className="onDisplay"
              />
            )}
            {imageNo === 2 && (
              <img
                src={item.IMG2 ? item.IMG2 : defaultImG}
                alt="Phone Image"
                className="onDisplay"
              />
            )}
            {/* {imageNo === 3 && (
              <img
                src={item.IMG3 ? item.IMG3 : defaultImG}
                alt='Phone Image'
                className='onDisplay'
              />
            )} */}
          </div>
          <div className="imageOptions">
            <div className="option" onClick={() => setImageNo(1)}>
              <img src={item.IMG1 ? item.IMG1 : defaultImG} />
            </div>
            <div className="option" onClick={() => setImageNo(2)}>
              <img src={item.IMG2 ? item.IMG2 : defaultImG} />
            </div>
            {/* <div className='option' onClick={() => setImageNo(3)}>
              <img src={item.IMG3 ? item.IMG3 : defaultImG} />
            </div> */}
          </div>
        </div>
        <div className="infoContainer">
          <h1>
            {item.Model} ({categoryOnDisplay})
          </h1>
          <h3>
            {" "}
            ₹ {priceOnDisplay}{" "}
            {!isNaN(item.MRP) ? (
              <span className="cardSave">Save {save}%</span>
            ) : (
              ""
            )}{" "}
          </h3>
          <h4>MRP: ₹ {item.MRP}</h4>
          {item.Details ? (
            <h6>{item.Details}</h6>
          ) : (
            <h6>
              Details about this model is currently not avaliable
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </h6>
          )}
          <h5>
            <i>Unique Product Code:</i> <b>{uniqueCode}</b>
          </h5>
          <button
            onClick={() =>
              // window.open('https://e4h3uqkvd80.typeform.com/to/mBRbqFb7')
              handlePayment()
            }
            id="rzp-button1"
          >
            Buy now
          </button>
        </div>
      </div>
      <div className="qna">
        <div onClick={() => alert("Coming soon...")}>
          <span>Product Support</span>
          <img src={arrow} className="qnaArrow" />
        </div>
        <div onClick={() => alert("Coming soon...")}>
          <span>FAQ</span>
          <img src={arrow} className="qnaArrow" />
        </div>
        <div onClick={() => alert("Coming soon...")}>
          <span>Our Buyer Guide</span>
          <img src={arrow} className="qnaArrow" />
        </div>
      </div>
    </div>
  );
}
