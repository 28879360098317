import React, { useState, useEffect } from "react";
import "./Login.css";
import { Nav, NavLink } from "react-bootstrap";
import LoginImg from "../assets/Images/Login.png";
import vector from "../assets/Images/Vector.png";
import { auth } from "../firebase/firebase";
import { Firebase } from "../firebase/firebase";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import { loadcaptchaverifier, loginuser, ValidateOtp } from "./Auth/loginuser";
import { getAuth } from "@firebase/auth";
export default function Login() {
  const [codesent, setCodesent] = useState(false);
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [final, setFinal] = useState("");
  const [message, setMessage] = useState("");
  const [login, setLogin] = useState("");
  const history=useHistory();
  const onSignInSubmit = (e) => {
    loginuser(phone, setMessage, setLogin, setCodesent, setFinal);
  };

  const onOtpSubmit = () => {
    ValidateOtp(otp,final,setLogin);
  };
 
  return (
    <div className="mainContainer">
      <div className="TYScontainer">
        {codesent ? (
          <div className="form">
            <h2 style={{ width: "80%", textAlign: "left" }}>
              Code Verification
            </h2>
            <input
              type="text"
              placeholder="Code"
              onChange={(e) => setOTP(e.target.value)}
              value={otp}
            />

            <br />
            <button type="submit" onClick={() => onOtpSubmit()}>
                Validate Code
                <img src={vector} alt="" />{" "}
              </button>
          </div>
        ) : (
          <div className="form">
            <h2 style={{ width: "80%", textAlign: "left" }}>Login</h2>
            <div id="capthca"></div>
            {/* <input type="text" placeholder="Email Address"/>
                    <h6>or</h6> */}
            <input
              type="number"
              placeholder="Phone number"
              onChange={(e) => setPhone("+91"+e.target.value)}
            />
            <br />

            <button id="sign-in-button" onClick={() => onSignInSubmit()}>
              Send Code
              <img src={vector} alt="" />{" "}
            </button>

            <NavLink href="/signup">Dont have an account? Sign Up</NavLink>
          </div>
        )}
        <div className="forminfo">
          <p>Kindly fill the details to login</p>
          <div>
            <img
              src={LoginImg}
              alt=" "
              style={{ width: "25vh", marginBottom: "5vh" }}
            />
          </div>

          <div id="recaptcha-container"></div>
        </div>
      </div>
    </div>
  );
}
