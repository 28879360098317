import React, { useState ,useEffect} from 'react';
import './Login.css';
import LoginImg from '../assets/Images/Login.png';
import vector from '../assets/Images/Vector.png';
import axios from 'axios';
import { useHistory } from 'react-router';
import { createuser,loadcaptchaverifier } from './Auth/createuser';
export default function Signup() {
  const [codesent, setCodesent] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [otp, setOTP] = useState();
  const [usercreated,setUsercreated]=useState(false)
  const history = useHistory();

  const handlesubmit=()=>{
    createuser(name,email,phone,setUsercreated,setMessage);
  }
  
  useEffect(()=>{
    if(usercreated==true){
      history.push("/login");
    }
  },[usercreated])
  return (
    <div className='mainContainer'>
      <div className='TYScontainer'>
        {/* {codesent ? (
          <div className="form">
            <h2 style={{ width: "80%", textAlign: "left" }}>
              Code Verification
            </h2>
            <input
              type="text"
              placeholder="Code"
              onChange={(e) => setOTP(e.target.value)}
            />

            <br />
            <button type="submit" onClick={() => checkOTP()}>
              Login <img src={vector} alt="" />{" "}
            </button>
          </div>
        ) : (
          <div className="form">
            <h2 style={{ width: "80%", textAlign: "left" }}>Sign Up</h2>
            <input
              type="text"
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email Address"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="number"
              placeholder="Phone number"
              onChange={(e) => setPhone(e.target.value)}
            />
            <br />
            <button type="submit" onClick={() => setCodesent(true)}>
              Send Code <img src={vector} alt="" />{" "}
            </button>
          </div>
        )} */}

        <div className='form'>
          <h2 style={{ width: '80%', textAlign: 'left' }}>Sign Up</h2>
          <input
            type='text'
            placeholder='Full Name'
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type='text'
            placeholder='Email Address'
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='number'
            placeholder='Phone number'
            onChange={(e) => setPhone(e.target.value)}
          />
          <br />
          <button type='submit' onClick={() => handlesubmit()}>
            Register <img src={vector} alt='' />{' '}
          </button>
        </div>
        <div className='forminfo'>
          <p>Kindly fill the details to login</p>
          <div>
            <img
              src={LoginImg}
              alt=' '
              style={{ width: '25vh', marginBottom: '5vh' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
