import React, { useState } from 'react';
import './AdminSidebar.css';

import deskLogo from '../assets/Images/deskLogo.png';

export default function AdminSidebar({ pageActive, setPage }) {
  //   const [pageActive, setPage] = useState(1);
  return (
    <nav className='sidebarMain'>
      <img src={deskLogo} />
      <h2>Hi Admin</h2>
      <h3>Orders</h3>
      <h4
        className={pageActive === 1 ? 'pageActiveAdmin' : ''}
        onClick={() => setPage(1)}
      >
        My orders
      </h4>
      <h3>Product</h3>
      <h4
        className={pageActive === 2 ? 'pageActiveAdmin' : ''}
        onClick={() => setPage(2)}
      >
        My products
      </h4>
      <h4
        className={pageActive === 3 ? 'pageActiveAdmin' : ''}
        onClick={() => setPage(3)}
      >
        Add new product
      </h4>
      <h4
        className={pageActive === 4 ? 'pageActiveAdmin' : ''}
        onClick={() => setPage(4)}
      >
        Product violation
      </h4>
    </nav>
  );
}
