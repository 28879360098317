import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import defaultImG from '../../assets/Images/defaultMobile.png';

import './RefurbishedCard.css';
export default function RefurbishedCard({ item }) {
  let priceOnDisplay = 0;
  let categoryOnDisplay = 'A';
  if (item.PriceA) {
    priceOnDisplay = item.PriceA;
    categoryOnDisplay = 'A';
  } else if (item.PriceB) {
    priceOnDisplay = item.PriceB;
    categoryOnDisplay = 'B';
  } else if (item.PriceC) {
    priceOnDisplay = item.PriceC;
    categoryOnDisplay = 'C';
  }
  let save = (100 - (priceOnDisplay / item.MRP) * 100).toFixed(1);
  let imageOnDisplay = defaultImG;
  if (item.IMG1) {
    imageOnDisplay = item.IMG1;
  } else if (item.IMG2) {
    imageOnDisplay = item.IMG2;
  } else if (item.IMG3) {
    imageOnDisplay = item.IMG3;
  }
  return (
    <Link className='refurbCard' to={'/' + item.Model.split(' ').join('-')}>
      <img loading='lazy' src={imageOnDisplay} />
      <h6>
        <b>{item.Model}</b>
        {/* {categoryOnDisplay} */}
      </h6>
      <h4>
        <span>₹ {priceOnDisplay}</span>
        <span>₹ {item.MRP}</span>{' '}
      </h4>
      {!isNaN(item.MRP) ? <p className='cardSave'>Save {save}%</p> : ''}
    </Link>
  );
}
