import React from 'react';
import './AddNewProduct.css';
import DashBar from './DashBar';

export default function AddNewProduct() {
  return (
    <div className='addnewMain'>
      <DashBar pageName='Add new products' />
      <h2>Add new products</h2>
      <form className='addNewForm'>
        <div className='addNewFeilds'>
          <h5>Product name</h5>
          <input type='text' placeholder='Product Name' />
        </div>
        <div className='addNewFeilds'>
          <h5>Price</h5>
          <input type='text' placeholder='Price' />
          <input type='text' placeholder='Price' />
        </div>
        <div className='addNewFeilds'>
          <h5>Price</h5>
          <input type='number' placeholder='Price' />
        </div>
        <div className='addNewFeilds'>
          <h5>Stock</h5>
          <input type='number' placeholder='Stock' />
        </div>
      </form>
    </div>
  );
}
