import React, { useState } from 'react';
import './RefurbishedMenu.css';
import arrow from '../../assets/Images/BottomArrow.png';

import support from '../../assets/Images/RefurbishedPhones/Support.png';
import account from '../../assets/Images/RefurbishedPhones/Account.png';
import saving from '../../assets/Images/RefurbishedPhones/Saving.png';
import checkon from '../../assets/Images/checkon.png';
import checkoff from '../../assets/Images/checkoff.png';
import filterBTN from '../../assets/Images/filterBTN.png';

import data from '../../assets/refurbData';
import RefurbishedCard from './RefurbishedCard';
import ReactSlider from 'react-slider';
export default function RefurbishedMenu() {
  const [category, setCategory] = useState(true);
  const [brand, setBrand] = useState(true);
  const [ram, setRam] = useState(false);
  const [strg, setStrg] = useState(false);
  const [filter, setFilter] = useState(false);
  const defaultFilterChoices = {
    category: {
      A: true,
      B: true,
      C: true,
    },
    price: {
      lower: 0,
      upper: 35000,
    },
    brand: {
      apple: true,
      samsung: true,
      oneplus: true,
      xiaomi: true,
      realme: true,
      vivo: true,
      oppo: true,
      others: true,
    },
    ram: {
      GB1: true,
      GB2: true,
      GB3: true,
      GB4: true,
      GB6: true,
      GB8: true,
      GB12: true,
    },
    storage: {
      GB8: true,
      GB16: true,
      GB32: true,
      GB64: true,
      GB128: true,
      GB256: true,
    },
  };
  function setLocalStorageToDefault() {
    localStorage.setItem('RP-UCWF', JSON.stringify(defaultFilterChoices));
  }
  if (!localStorage.getItem('RP-UCWF')) {
    setLocalStorageToDefault();
  }
  const [stateStorage, setStateStorage] = useState(
    JSON.parse(localStorage.getItem('RP-UCWF'))
  );
  let storageOBJ = JSON.parse(localStorage.getItem('RP-UCWF'));

  var filteredData = data;
  //Category filters
  if (!stateStorage.category.A) {
    filteredData = filteredData.filter((item) => item.A === null);
  }
  if (!stateStorage.category.B) {
    filteredData = filteredData.filter((item) => item.B === null);
  }
  if (!stateStorage.category.C) {
    filteredData = filteredData.filter((item) => item.C === null);
  }
  //Brands filters
  if (!stateStorage.brand.apple) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'apple'
    );
  }
  if (!stateStorage.brand.samsung) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'samsung'
    );
  }
  if (!stateStorage.brand.oneplus) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'oneplus'
    );
  }
  if (!stateStorage.brand.xiaomi) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'xiaomi'
    );
  }
  if (!stateStorage.brand.realme) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'realme'
    );
  }
  if (!stateStorage.brand.vivo) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'vivo'
    );
  }
  if (!stateStorage.brand.oppo) {
    filteredData = filteredData.filter(
      (item) => item.Model.split(' ')[0].toLocaleLowerCase() !== 'oppo'
    );
  }
  if (!stateStorage.brand.others) {
    filteredData = filteredData.filter((item) => {
      let BName = item.Model.split(' ')[0].toLocaleLowerCase();
      return (
        BName === 'apple' ||
        BName === 'samsung' ||
        BName === 'oneplus' ||
        BName === 'xiaomi' ||
        BName === 'realme' ||
        BName === 'vivo' ||
        BName === 'oppo'
      );
    });
  }

  //RAM filters
  if (!stateStorage.ram.GB1) {
    filteredData = filteredData.filter((item) => item.RAM !== 1);
  }
  if (!stateStorage.ram.GB2) {
    filteredData = filteredData.filter((item) => item.RAM !== 2);
  }
  if (!stateStorage.ram.GB3) {
    filteredData = filteredData.filter((item) => item.RAM !== 3);
  }
  if (!stateStorage.ram.GB4) {
    filteredData = filteredData.filter((item) => item.RAM !== 4);
  }
  if (!stateStorage.ram.GB6) {
    filteredData = filteredData.filter((item) => item.RAM !== 6);
  }
  if (!stateStorage.ram.GB8) {
    filteredData = filteredData.filter((item) => item.RAM !== 8);
  }
  if (!stateStorage.ram.GB12) {
    filteredData = filteredData.filter((item) => item.RAM !== 12);
  }
  //Storage filters
  if (!stateStorage.storage.GB8) {
    filteredData = filteredData.filter((item) => item.Storage !== 8);
  }
  if (!stateStorage.storage.GB16) {
    filteredData = filteredData.filter((item) => item.Storage !== 16);
  }
  if (!stateStorage.storage.GB32) {
    filteredData = filteredData.filter((item) => item.Storage !== 32);
  }
  if (!stateStorage.storage.GB64) {
    filteredData = filteredData.filter((item) => item.Storage !== 64);
  }
  if (!stateStorage.storage.GB128) {
    filteredData = filteredData.filter((item) => item.Storage !== 128);
  }
  if (!stateStorage.storage.GB256) {
    filteredData = filteredData.filter((item) => item.Storage !== 256);
  }
  //Price Range Slider filter
  filteredData = filteredData.filter((item) => {
    if (item.PriceA !== null) {
      return (
        item.PriceA >= stateStorage.price.lower &&
        item.PriceA <= stateStorage.price.upper
      );
    } else if (item.PriceB !== null) {
      return (
        item.PriceB >= stateStorage.price.lower &&
        item.PriceB <= stateStorage.price.upper
      );
    } else if (item.PriceC !== null) {
      return (
        item.PriceC >= stateStorage.price.lower &&
        item.PriceC <= stateStorage.price.upper
      );
    }
  });

  function updateContent() {
    localStorage.setItem('RP-UCWF', JSON.stringify(storageOBJ));
    setStateStorage(JSON.parse(localStorage.getItem('RP-UCWF')));
  }

  let A_Count, B_Count, C_Count;
  A_Count = data.filter((item) => item.A).length;
  B_Count = data.filter((item) => item.B).length;
  C_Count = data.filter((item) => item.C).length;
  return (
    <div className='RefurbishedMenuMain'>
      <title>Best deals on second hand mobile with warranty, best prices on Refurbished phones</title>
      <nav className='sideFilterButton' onClick={() => setFilter(!filter)}>
        <img src={filterBTN} />
      </nav>
      <div>
        <div className={'sideFilters ' + (filter ? 'viewIn' : 'viewOut')}>
          <h3>Filters ({filteredData.length})</h3>
          <button
            onClick={() => {
              storageOBJ = defaultFilterChoices;
              updateContent();
            }}
          >
            Clear Filters
          </button>
          <div className='filterCategory'>
            <span>Category</span>
            <img
              src={arrow}
              className={category ? '' : 'rotate180'}
              onClick={() => setCategory(!category)}
            />
          </div>
          <section className={'filterCatContent ' + (category ? '' : 'rollup')}>
            <div
              className={
                'filterCheck ' + (!stateStorage.category.A && 'checkThrough')
              }
              onClick={() => {
                storageOBJ.category.A = !stateStorage.category.A;
                updateContent();
              }}
            >
              <img
                src={stateStorage.category.A ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Category A</span>
              <span>{A_Count}</span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.category.B = !stateStorage.category.B;
                updateContent();
              }}
            >
              <img
                src={stateStorage.category.B ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Category B</span>
              <span>{B_Count}</span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.category.C = !stateStorage.category.C;
                updateContent();
              }}
            >
              <img
                src={stateStorage.category.C ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Category C</span>
              <span>{C_Count}</span>
            </div>
          </section>
          <div className='filterCategory'>
            <span>Price</span>
          </div>
          <section className={'filterCatContent '}>
            <ReactSlider
              className='horizontal-slider'
              thumbClassName='example-thumb'
              trackClassName='example-track'
              step={5000}
              min={0}
              max={35000}
              defaultValue={[0, 35000]}
              ariaLabel={['Lower thumb', 'Upper thumb']}
              ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
              renderThumb={(props, state) => (
                <div {...props}>
                  {state.valueNow === 0 ? 0 : state.valueNow / 1000 + 'k'}
                </div>
              )}
              pearling
              minDistance={5000}
              value={[stateStorage.price.lower, stateStorage.price.upper]}
              onChange={(value, index) => {
                storageOBJ.price.lower = value[0];
                storageOBJ.price.upper = value[1];
                updateContent();
              }}
            />
          </section>
          <div className='filterCategory'>
            <span>Brand</span>
            <img
              src={arrow}
              className={brand ? '' : 'rotate180'}
              onClick={() => setBrand(!brand)}
            />
          </div>
          <section className={'filterCatContent ' + (brand ? '' : 'rollup')}>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.apple = !stateStorage.brand.apple;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.apple ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Apple</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.samsung = !stateStorage.brand.samsung;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.samsung ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Samsung</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.oneplus = !stateStorage.brand.oneplus;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.oneplus ? checkon : checkoff}
                className='checkIMG'
              />
              <span>OnePlus+</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.xiaomi = !stateStorage.brand.xiaomi;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.xiaomi ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Xiaomi</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.realme = !stateStorage.brand.realme;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.realme ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Realme</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.vivo = !stateStorage.brand.vivo;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.vivo ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Vivo</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.oppo = !stateStorage.brand.oppo;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.oppo ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Oppo</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.brand.others = !stateStorage.brand.others;
                updateContent();
              }}
            >
              <img
                src={stateStorage.brand.others ? checkon : checkoff}
                className='checkIMG'
              />
              <span>Others</span>
              <span></span>
            </div>
          </section>
          <div className='filterCategory'>
            <span>RAM</span>
            <img
              src={arrow}
              className={ram ? '' : 'rotate180'}
              onClick={() => setRam(!ram)}
            />
          </div>
          <section className={'filterCatContent ' + (ram ? '' : 'rollup')}>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB1 = !stateStorage.ram.GB1;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB1 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>1GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB2 = !stateStorage.ram.GB2;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB2 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>2GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB3 = !stateStorage.ram.GB3;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB3 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>3GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB4 = !stateStorage.ram.GB4;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB4 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>4GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB6 = !stateStorage.ram.GB6;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB6 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>6GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB8 = !stateStorage.ram.GB8;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB8 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>8GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.ram.GB12 = !stateStorage.ram.GB12;
                updateContent();
              }}
            >
              <img
                src={stateStorage.ram.GB12 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>12GB</span>
              <span></span>
            </div>
          </section>
          <div className='filterCategory'>
            <span>Storage</span>
            <img
              src={arrow}
              className={strg ? '' : 'rotate180'}
              onClick={() => setStrg(!strg)}
            />
          </div>
          <section className={'filterCatContent ' + (strg ? '' : 'rollup')}>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.storage.GB8 = !stateStorage.storage.GB8;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB8 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>8GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.storage.GB16 = !stateStorage.storage.GB16;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB16 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>16GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck '
              onClick={() => {
                storageOBJ.storage.GB32 = !stateStorage.storage.GB32;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB32 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>32GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.storage.GB64 = !stateStorage.storage.GB64;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB64 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>64GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.storage.GB128 = !stateStorage.storage.GB128;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB128 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>128GB</span>
              <span></span>
            </div>
            <div
              className='filterCheck'
              onClick={() => {
                storageOBJ.storage.GB256 = !stateStorage.storage.GB256;
                updateContent();
              }}
            >
              <img
                src={stateStorage.storage.GB256 ? checkon : checkoff}
                className='checkIMG'
              />
              <span>256GB</span>
              <span></span>
            </div>
          </section>
          {/* <button>Apply Filters(2)</button> */}
        </div>
        {filteredData.length === 0 ? (
          <div className='noPhones'>
            <h2>No Phones to show</h2>
            <h3>Change the filter settings</h3>
          </div>
        ) : (
          <div className='PhonesInDisplay'>
            {filteredData.map((item, index) => (
              <RefurbishedCard item={item} />
            ))}
          </div>
        )}
      </div>
      <div className='supaccsav'>
        <div>
          <img src={support} alt='' />
          <h4>Product Support</h4>
          <h6>
            Up to 3 years on-site warranty available for your peace of mind.
          </h6>
        </div>
        <div>
          <img src={account} alt='' />
          <h4>Personal Account</h4>
          <h6>
            With big discounts, free delivery and a dedicated support
            specialist.
          </h6>
        </div>
        <div>
          <img src={saving} alt='' />
          <h4>Amazing Savings</h4>
          <h6>
            Up to 70% off new Products, you can be sure of the best price.
          </h6>
        </div>
      </div>
    </div>
  );
}
