import React, {  useState, useEffect } from 'react'
import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import arrow from '../assets/Images/Vector.png'
import waterfilter from '../assets/Images/Services/Waterfilter/Waterpurifier.png'
import makeitbetter from '../assets/Images/Services/Waterfilter/makeitbetter.png'
import brandsweserve from '../assets/Images/Services/Waterfilter/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'


import repair from '../assets/Images/Services/Waterfilter/Group 434.png'
import annualmen from '../assets/Images/Services/Waterfilter/Group 435.png'
import install from '../assets/Images/Services/Waterfilter/Group 437.png'
import uninstall from '../assets/Images/Services/Waterfilter/Group 439.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'

export default function Waterfilter() {
    const [items, setItems] = useState([
        {name: 'Repair', content: "Ro not working? Or do you require maintenance and cleaning? Damage to the body of the RO pre-filter, noise from the RO, water spilling from the RO, and so on. Call us for RO water purifier repair, and we'll take care of all your issues.", image: repair, price: 399, quantity: 0},

        {name: 'Annual maintenance', content: "Our RO Water Purifier AMC Plan includes all the benefits of the Extended Warranty plan, as well as preventative services (for example, filter cleaning, servicing, and replacement). We have the greatest AMC plans available for all of your RO Water Purifier needs, all in one spot.", image: annualmen, price: 1999, quantity: 0},

        {name: 'Installation', content: "Have you just bought a Water purifier? Do you require assistance with your installation? Our technicians follow the manufacturer's guidelines for installing RO. It usually takes an hour or two to complete installation.", image: install, price: 499, quantity: 0},

        {name: 'Uninstallation', content: "Having trouble with the uninstallation of RO upon shifting we can help you with the setup. You can reach us by phone or fill a simple service form to learn more about the pricing.", image: uninstall, price: 399, quantity: 0},
    ])

 
    
     const addtocart = (item,index, qty) =>{
         console.log('adding to cart', index, qty)
         var updatedItems = [...items]
         updatedItems[index].quantity = qty
         setItems(updatedItems)
         var cartlist = items.filter((item)=>item.quantity>0)
         localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
     }
    // console.log(items)
    return (
        <div className="serviceMain waterpurifier">
            <title>Hassle-free Water purifier service & RO repair service at the lowest prices</title>
            <div className="serviceHead">
                <img src={waterfilter} alt="" />
                <div>
                    <h1 className="heading">Water Purifier</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>A water filter is becoming a must-have item in every Indian household. Water that is both safe and pure is crucial for your family's health. You've come to the right site if you're seeking the greatest water purifier repair service. Our technicians have years of experience with RO water filtration systems. <br/><br/>
                    The water purifier requires maintenance every three to four months to guarantee that it continues to perform effectively. No matter what your need is, whether you need RO water purifier system repair or installation, We're your best and most reliable provider.
                    </h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>The prevalence of waterborne diseases makes it important to regularly service water purifiers. If you have faulty Ro purifiers, Below are some of the most popular services we provide:</h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map(
                            (item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>The device and service can be chosen based on your preferences. In case you need help placing the order, just contact us at 9017-247-247 or fill in the form by telling us what service you want and click on the connect button for a callback.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>Your RO water purifier will be repaired on-site. If there is a tiny problem, Our technicians will take care of it right away. If any part is missing or needs to be repaired then Our expert technicians will diagnose and fix your device to perfection.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>Your device will be like new within 2-5 days depending upon the issue and the spare part availability.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
