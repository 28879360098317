import React ,{useContext,useEffect}from "react";
import { AuthContext } from "../Auth/AuthProvider";
import logo from '../../assets/Images/logoicon.png';
import "./AdminAddProduct.css"
const AdminAddProduct = () => {
  const context=useContext(AuthContext);
  const setIsadmin=context.value.setIsadmin;
  useEffect(()=>{
    setIsadmin(true)
  },[])
  return (
    <>
    <div className="maindiv">
     
      <div className="leftchilddiv">
        <div className="leftchildfirst">
          <div className="leftchildfirstlogodiv">
            <img src={logo} className="leftchildfirstlogo" />
          </div>
          <div className="leftchildfirstname">
<span className="leftchildfirstnametext">Hi! user</span>
          </div>
          <div className="leftchildfirstmenu">
          
          </div>
        </div>
        <div className="leftchildsecond"></div>
      </div>
      <div className="rightchilddiv"></div>
      </div>
    </>
  );
};

export default AdminAddProduct;
