import React from 'react';
import { Nav } from 'react-bootstrap';
import LogoMobile from '../assets/Images/logoicon.png';
import LogoDesktop from '../assets/Images/deskLogo.png';
import Call from '../assets/Images/Call.png';
import Menu from '../assets/Images/Menu.png';
import cartpng from '../assets/Images/Cart.png';
import Profile from '../assets/Images/Profile.png';

export default function MainNavbar({
  scroll,
  nav,
  setNav,
  cartprof,
  setCartprof,
}) {
  return (
    <div className={'NavbarMain '}>
      <div
        className={
          'NavbarContainer ' +
          (scroll >= 15 && 'NavScrolled ') +
          ' ' +
          (cartprof > 0 && 'NavDarkened ') +
          ' ' +
          (nav && 'NavDarkened ')
        }
      >
        <div className='logoContainer'>
          <Nav.Link href='/'>
            <img src={LogoMobile} className='LogoMobile' />
            <img src={LogoDesktop} className='LogoDesktop' />
          </Nav.Link>
        </div>
        <div className='Navlinks'>
          <Nav.Link onClick={() => setCartprof(3)}>
            <h4>Repair services</h4>
          </Nav.Link>
          <Nav.Link href='/we-fix-repair'>
            <h4>Wefixcare</h4>
          </Nav.Link>
          <Nav.Link href='/trackyourservice'>
            <h4>Track your services</h4>
          </Nav.Link>
          <div onClick={() => setCartprof(1)}>
            <img src={Profile} />
          </div>
          <div onClick={() => setCartprof(2)}>
            <img src={cartpng} />
          </div>
        </div>
        <a
          style={{ textDecoration: 'none' }}
          href='tel:9017247247'
          className='NavCall'
        >
          <div>
            <img src={Call} /> <span>+91 9017-247-247</span>{' '}
          </div>
          <div>
            <a href='tel:8882935167'>Call Now</a>
          </div>
        </a>
        <div className='cartprofContainer'>
          <a
            style={{ textDecoration: 'none' }}
            href='tel:8882935167'
            className='NavCallMobile'
          >
            <div>
              <img style={{ width: 20 }} src={Call} />{' '}
              <span style={{ color: '#243C80', fontWeight: 800 }}>
                +91 9017-247-247
              </span>{' '}
            </div>
            <div>
              <a href='tel:9017247247' style={{ color: '#243C80' }}>
                Call Now
              </a>
            </div>
          </a>
          {/* <div className="cartprof" onClick={()=> setCartprof(1)}><img src={Profile}/></div>
                        <div className="cartprof" onClick={()=> setCartprof(2)}><img src={cartpng}/></div> */}
        </div>
        <div
          className='menuBTN'
          onClick={() => {
            setNav(!nav);
          }}
        >
          <img src={Menu} />
        </div>
      </div>
      <div
        className={
          'menuContainer ' + (nav ? 'NavbarMainActive' : 'NavbarMainInActive')
        }
      >
        <Nav.Link
          onClick={() => {
            setCartprof(3);
            setNav(false);
          }}
        >
          <h4>Repair</h4>
        </Nav.Link>
        <Nav.Link href='/we-fix-repair'>
          <h4>Wefixcare</h4>
        </Nav.Link>
        <Nav.Link href='/trackyourservice'>
          <h4></h4>
        </Nav.Link>
      </div>
    </div>
  );
}
