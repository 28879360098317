import React from 'react';
import arrow from '../../assets/Images/Vector.png';
import { Link } from 'react-router-dom';

import './Wefixcare.css';

export default function Othersevicecard({ item }) {
  return (
    <div className='otherservicescardMain'>
      <div className='otherservicescard'>
        <h1>{item.Name} </h1>
        <h5>{item.Content} </h5>
        <img src={item.Image} alt='' />
      </div>
      {item.Link ? (
        <Link to={item.Link} className='addtocart2'>
          Take a look
          <img src={arrow} alt='' />
        </Link>
      ) : (
        <div
          onClick={() => {
            window.open('https://e4h3uqkvd80.typeform.com/to/KYqGYqzK');
          }}
          className='addtocart2'
        >
          Book a service
          <img src={arrow} alt='' />
        </div>
      )}
    </div>
  );
}
