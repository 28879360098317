import { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Routes, Route, useHistory } from 'react-router-dom';
import './App.css';
// import Sitemap from './sitemap.xml';
import Homepage from './Pages/Homepage';
import ContactUsPage from './Pages/ContactUs';
import Footer from './Components/Footer';
import TrackYourService from './Pages/TrackYourService';
import MainNavbar from './Components/NavBar';
import Cart from './Components/Cart';
import Profile from './Components/Profile';
import Blog from './Pages/Blog';
import Login from './Pages/Login';
import Wefixcare from './Pages/wefixcare/Wefixcare';
import Repairservices from './Components/Repairservices';
import Requestcallback from './Components/Requestcallback';
import MobilePhones from './Pages/MobilePhone';
import Tablet from './Pages/Tablet';
import CCTV from './Pages/CCTV';
import Waterfilter from './Pages/Waterfilter';
import Airconditioner from './Pages/Airconditioner';
import Washingmachine from './Pages/Washingmachine';
import LCD from './Pages/LCD';
import Laptop from './Pages/Laptop';
import Checkout from './Pages/Checkout';
import Allblogs from './Pages/Allblogs';
import Signup from './Pages/Signup';
import Purchase from './Pages/purchase/Purchase';
import Policy from './Pages/Policy';

import whatsappIMG from './assets/Images/whatsapp.png';
import RefurbishedMenu from './Pages/Refurbished/RefurbishedMenu';
import RefurbishedItem from './Pages/Refurbished/RefurbishedItem';
// import RefurbishedPhonesRoutes from './RefurbishedPhonesRoutes';
import data from './assets/refurbData';
import PrivateRoute from './Pages/Auth/PrivateRoute';
import Dashboard from './Pages/Dashboard/Dashboard';
import AuthProvider from './Pages/Auth/AuthProvider';
import { AuthContext } from './Pages/Auth/AuthProvider';
import ListProducts from './Pages/Admin/ListProducts';
import AddProduct from './Pages/Admin/AddProduct';
import AdminAddProduct from './Pages/Admin/AdminAddProduct';
import { getAuth } from '@firebase/auth';
import AdminHome from './Admin/AdminHome';
import ContactForm from "./Pages/ContactForm/ContactForm"

function App() {
  const context = useContext(AuthContext);
  const [cartprof, setCartprof] = useState(0);
  const [scroll, setScroll] = useState(0);
  const [nav, setNav] = useState(false);
  const [currentUser, setCurrentUser] = useState(false);
  const isadmin = context.value.isadmin;
  let history = useHistory();
  useEffect(() => {
    getAuth().onAuthStateChanged(async (user) => {
      if (user != null) {
        setCurrentUser(user);
      }
    });
  }, [setCurrentUser]);
  function onScroll() {
    setScroll(window.pageYOffset);
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    setTimeout(() => {
      setCartprof(0);
    }, 4000);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const movetoCheckout = () => {
    console.log('taking user to checkout page');
    history.push('./checkout');
  };

  return (
    <div class='App'>
      {!isadmin && (
        <MainNavbar
          scroll={scroll}
          nav={nav}
          setNav={setNav}
          cartprof={cartprof}
          setCartprof={setCartprof}
        />
      )}
      {cartprof === 4 && <Requestcallback setCartprof={setCartprof} />}
      {cartprof === 3 && <Repairservices setCartprof={setCartprof} />}
      {cartprof === 2 && (
        <Cart movetoCheckout={movetoCheckout} setCartprof={setCartprof} />
      )}
      {cartprof === 1 && <Profile setCartprof={setCartprof} />}

      <Routes>
        <Route
          exact
         
          path='/dashboard'
        >
         <PrivateRoute component={Dashboard} />
        </Route>
        {/* <Route
          exact
         
          path='/admin'
        >
          <PrivateRoute component={ListProducts} />
        </Route> */}
         {/* <Route exact path="/sitemap" component={Sitemap} /> */}
        <Route
          exact
       
          path='/admin/addnewproduct'
        >
          <PrivateRoute component={AdminAddProduct} />
        </Route>
        <Route exact  path='/'>
        <Homepage />
        </Route>
        <Route  path='/contactus'>
        <ContactUsPage />
        </Route>
        <Route  path='/we-fix-repair'>
        <Wefixcare />
        </Route>
        <Route  path='/trackyourservice'>
        <TrackYourService />
        </Route>
        <Route  path='/login'>
        <Login />
        </Route>
        <Route  path='/signup'>
        <Signup />
        </Route>
        <Route path='/checkout'>
        <Checkout />
        </Route>
        <Route  path='/purchase'>
        <Purchase />
        </Route>

        <Route  path='/mobile-repair'>
        <MobilePhones />
        </Route>
        <Route  path='/tablet-repair-service'>
        <Tablet />
        </Route>
        <Route  path='/cctv-installation-service'>
        <CCTV />
        </Route>
        <Route  path='/water-purifier-service'>
        <Waterfilter />
        </Route>
        <Route  path='/ac-repair-service'>
        <Airconditioner />
        </Route>
        <Route
          
          path='/washing-machine-repair'
        >
          <Washingmachine />
        </Route>
        <Route  path='/tv-repair-service'>
        <LCD />
        </Route>
        <Route  path='/laptop-repair-service'>
        <Laptop />
        </Route>

        <Route  path='/refurbished-phones'>
        <RefurbishedMenu />
        </Route>
        <Route
          
          path='/refurbished-phonename'
        >
          <RefurbishedItem />
        </Route>

        <Route  path='/allblogs'>
        <Allblogs />
        </Route>
        <Route  path='/blog1'>
        <Blog blogNo={1} />
        </Route>
        <Route  path='/blog2'><Blog blogNo={2} /></Route>
        <Route  path='/blog3'><Blog blogNo={3} /></Route>
        <Route  path='/blog4'><Blog blogNo={4} /></Route>
        <Route  path='/blog5'><Blog blogNo={5} /></Route>
        <Route  path='/blog6'><Blog blogNo={6} /></Route>
        <Route  path='/blog7'><Blog blogNo={7} /></Route>

        <Route  path='/privacy-policy'>
        <Policy policyNo={1} />
        </Route>
        <Route
          
          path='/terms-and-conditions'
        ><Policy policyNo={2} /></Route>
        <Route
          
          path='/warranty-policy'
        ><Policy policyNo={3} /></Route>

        {data.map((item, index) => (
          //   (item) => console.log(item.Model.split(' ').join('-'))
          <Route
            
            path={'/' + item.Model.split(' ').join('-')}
          ><RefurbishedItem item={item} index={index} /></Route>
        ))}

        <Route  path='/adminhome' ><PrivateRoute component={AdminHome} /></Route>
        <Route path='/contactForm' ><ContactForm /></Route>
      </Routes>

      {!isadmin && (
        <div
          className='whatsapp'
          onClick={() =>
            window.open(
              'https://api.whatsapp.com/send?phone=919017247247&text=Hi'
            )
          }
        >
          <img src={whatsappIMG} />
        </div>
      )}
      {!isadmin && <Footer />}
    </div>
  );
}

export default App;
