import React, { useState } from 'react'
import arrow from '../../assets/Images/Vector.png'
import Applicationmodal from '../wefixcare/Applicationmodal'

export default function PurchaseAppCard({item}) {
    const [appModal , setAppModal] = useState(false)
    return (
        <div className="purchaseAppMain">
            {/* {
                appModal ? <Applicationmodal item={item} setAppModal={setAppModal}/> : ''        
            } */}
            <div className="purchaseCard">
                <div></div>
                <div>
                    <h1>{item.Name}</h1>
                    <h1>Price <br/><span style={{fontWeight: 'bold', fontSize: '4vh'}} >₹4499</span> </h1>
                </div>
                <img src={item.AppImage} alt="" />
            </div>
            <div className="addtocart3" onClick={()=> setAppModal(true)}>
                View Service
                <img src={arrow} alt="" />
            </div>
        </div>
    )
}
