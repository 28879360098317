import React, {  useState, useEffect } from 'react'
import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import arrow from '../assets/Images/Vector.png'
import washingmachine from '../assets/Images/Services/LCD/LCD.png'
import makeitbetter from '../assets/Images/Services/LCD/makeitbetter.png'
import brandsweserve from '../assets/Images/Services/LCD/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'


import repair from '../assets/Images/Services/LCD/Group 426.png'
import annual from '../assets/Images/Services/LCD/Group 427.png'
import install from '../assets/Images/Services/LCD/Group 430.png'
import uninstall from '../assets/Images/Services/LCD/Group 432.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'


export default function LCD() {
    const [items, setItems] = useState([
        {name: 'Repair', content: "All forms of LCD/LED repairs, including picture color issues, logic card issues, faulty motherboards, and malfunctioning screens or panels. There can be other reasons as well. Brands charge different prices for replaceable parts. Let us restore your LCD or LED television to make it perfect again.", image: repair, price: 499, quantity: 0},

        {name: 'Annual maintainance', content: "All of the benefits of the Extended Warranty Plan, as well as preventive maintenance, are included in the AMC Plan for LED/LCDs (ex: screen issue, voice issue). We will take care of breakdowns and maintenance as needed under our AMC", image: annual, price: 1999, quantity: 0},

        {name: 'Installation', content: "Do you want your new TV placed on the wall? The TV stand takes up a lot of space on the floor, thus wall mounting saves a lot of space. we're always ready to assist you on call!", image: install, price: 349, quantity: 0},

        {name: 'Uninstallation', content: "Choose this service If you're moving or changing homes, don't worry; we can also assist you with removal. We'll be at your door in minutes", image: uninstall, price: 249, quantity: 0},
    ])

    
 
    
     const addtocart = (item,index, qty) =>{
         console.log('adding to cart', index, qty)
         var updatedItems = [...items]
         updatedItems[index].quantity = qty
         setItems(updatedItems)
         var cartlist = items.filter((item)=>item.quantity>0)
         localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
     }
    // console.log(items)
    return (
        <div className="serviceMain LCD">
            <title>India’s best TV repair services at doorstep, & online CRT TV repairing at low prices</title>
            <div className="serviceHead">
                <img src={washingmachine} alt="" />
                <div>
                    <h1 className="heading">LCD/LED</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>Television is a vital component of our daily lives. Every family benefits from television for entertainment, sports, news, and kid engagement. A broken television is a terrible experience, and life appears to be monotonous. So, if your LED TV isn't working or you're having issues, you can get LED TV repair help right away without losing your patience. UCRACKWEFIX experts have all of the essential tools to efficiently resolve any minor and major LED/LCD TV issues at a reasonable cost. We solve TV picture difficulties, TV sound problems, TV not working, TV panel replacement, TV wall mounting services, and more, in addition to providing full TV repair services.</h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>A LED/LCD is an important source of entertainment and any problem with this can put you in bad mood few of the most common problems can be dealt with a variety of services, including:
                </h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map(
                            (item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you.
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>You can select the device and service based on your preferences. If you need assistance placing your order, please call us at 9017-247-247or fill out the form below by describing the service you require and pressing the connect button to receive a callback.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>Your LED/LCD will be fixed on-site. If there is a tiny problem, Our executive partner will take care of it right away. Our professional experts will diagnose and repair your gadget to perfection if any part is missing or needs to be repaired.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>Your device will be like new within 2-5 days depending upon the issue and the spare part availability.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
