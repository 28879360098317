import React from 'react'
import './Purchase.css'
import '../wefixcare/Wefixcare.css'

import empty from "../../assets/Images/emptyPurhase.png"
import PurchaseContainer from './PurchaseContainer'
import Servicecardcontainer from '../../Components/Servicecardcontainer'

import appliance1 from '../../assets/Images/Wefixcare/appliance1.png'
import appliance2 from '../../assets/Images/Wefixcare/appliance2.png'
import appliance3 from '../../assets/Images/Wefixcare/appliance3.png'
import appliance4 from '../../assets/Images/Wefixcare/appliance4.png'
import PurchaseAppCard from './PurchaseAppCard'

export default function Purchase() {
    const applianceList = [
        {Name: "Kitchen Appliances",AppImage: appliance1,Price: 1499, Content: "Hii I am the content", List: "one two three four"},
        {Name: "Home Appliances",AppImage: appliance2,Price: 1499, Content: "Hii I am the content", List: "one two three four"},
        {Name: "Gadgets",AppImage: appliance3,Price: 1499, Content: "Hii I am the content", List: "one two three four"},
        // {Name: "Aqua Hygeine",AppImage: appliance4,Price: 1499, Content: "Hii I am the content", List: "one two three four"},
    ]
    let orderHistoryList = [
        {
            OrderID: "ABC123456",
            OrderDate: "20th Sept 2021",
            TotalPrice: 2000,
            orderItems: [
                {
                    Name: "TV",
                    Price: 600
                },
                {
                    Name: "Mobile Phone",
                    Price: 800
                },
                {
                    Name: "Tablet",
                    Price: 600
                },
            ]
        },
        {
            OrderID: "DEF123456",
            OrderDate: "13th April 2021",
            TotalPrice: 2400,
            orderItems: [
                {
                    Name: "Water Purifier",
                    Price: 1200
                },
                {
                    Name: "CCTV",
                    Price: 1200
                },
            ]
        }
    ]
    return (
        <div className="purchaseMain">
            <div>
                <div className="purchaseHistory">
                    <h4>Purchase History</h4>
                    {orderHistoryList.length === 0?
                    <>
                        <img src={empty} alt="" />
                    </>
                    :<>
                        {orderHistoryList.map((items) => (
                        <PurchaseContainer items={items}/>
                        ))}
                    </>
                    }
                </div>
                <div className="AMCplans">
                    <h4>Checkout AMC plans</h4>
                    {applianceList.map((item)=> <PurchaseAppCard item={item}/> )}
                </div>
            </div>
            <Servicecardcontainer/>
            
        </div>
    )
}
