import { initializeApp } from "firebase/app";
import { getFirestore } from '@firebase/firestore';
import { getAuth,setPersistence, inMemoryPersistence,browserLocalPersistence,browserSessionPersistence} from '@firebase/auth';
import { getStorage,ref } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  // apiKey: "AIzaSyACchSwfw6woE9lMwWmfVCF11UOiE3eZ30",
  // authDomain: "ucrackwefix-f8a89.firebaseapp.com",
  // projectId: "ucrackwefix-f8a89",
  // storageBucket: "ucrackwefix-f8a89.appspot.com",
  // messagingSenderId: "971892799176",
  // appId: "1:971892799176:web:9158091c320e39280697fd"
  apiKey: "AIzaSyCZyklC2lmXkOZ2KI2WUSkjGXJVr6H3HPs",

  authDomain: "testurcrack.firebaseapp.com",

  projectId: "testurcrack",

  storageBucket: "testurcrack.appspot.com",

  messagingSenderId: "651508182684",

  appId: "1:651508182684:web:5072e331580dab3a874759"

};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);
const auth=getAuth();
setPersistence(auth, browserSessionPersistence)



const firestore = getFirestore();
const timestamp = new Date();
const storage = getStorage();

export const placeholder = "https://firebasestorage.googleapis.com/v0/b/smd-firebase-ca66a.appspot.com/o/placeholder_logo.png?alt=media&token=5d957eb4-8643-4988-b368-4723343b91fa"
export {Firebase,firestore,storage,timestamp}
// const messaging = firebase.messaging();
//
// messaging.onMessage((payload) => {
//     console.log('Message received. ', payload);
//     // ...
// });
// export {messaging}