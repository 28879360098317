import React from "react";
import Appliancecard from "./Appliancecard";
import "./Wefixcare.css";

import mobile from "../../assets/Images/Wefixcare/mobile.png";

import appliance1 from "../../assets/Images/Wefixcare/appliance1.png";
import appliance2 from "../../assets/Images/Wefixcare/appliance2.png";
import appliance3 from "../../assets/Images/Wefixcare/appliance3.png";
import appliance4 from "../../assets/Images/Wefixcare/appliance4.png";

import model1 from "../../assets/Images/Wefixcare/chimney.png";
import model2 from "../../assets/Images/Wefixcare/fridge.png";
import model3 from "../../assets/Images/Wefixcare/microwave.png";
import model4 from "../../assets/Images/Wefixcare/smart-tv.png";

import buy from "../../assets/Images/Wefixcare/buy.png";
import sell from "../../assets/Images/Wefixcare/sell.png";
import exchange from "../../assets/Images/Wefixcare/exchange.png";

import Modelcard from "./Modelcard";
import Othersevicecard from "./Othersevicecard";
import Servicecardcontainer from "../../Components/Servicecardcontainer";

export default function Wefixcare() {
  const applianceList = [
    {
      Name: "Kitchen Appliances",
      AppImage: appliance1,
      Price: 4499,
      Content:
        "With just one plan, protect all your kitchen appliance collection",
      List1: "Breakdown protection/total damage prevention",
      List2:
        "Upon purchase, all appliances are covered for a period of 10 years",
      List3: "Verified service experts",
      List4: "Guaranteed Visit within 6 hours",
    },
    {
      Name: "Home Appliances",
      AppImage: appliance2,
      Price: 5999,
      Content: "Get protection for all your home appliances in one plan",
      List1: "Maintenance/Breakdown protection/total damage prevention",
      List2:
        "Upon purchase, all Home appliances are covered for a period of 10 years",
      List3: "Verified service experts",
      List4: "Guaranteed Visit within 6 hours",
    },
    {
      Name: "Gadgets",
      AppImage: appliance3,
      Price: 3999,
      Content: "With our single plan, you can protect all your gadgets",
      List1: "Breakdown protection/total damage prevention",
      List2:
        "Upon purchase, all appliances are covered for a period of 5 years",
      List3: "Verified service experts",
      List4: "Guaranteed Visit within 6 hours",
    },
    {
      Name: "Aqua Hygeine",
      AppImage: appliance4,
      Price: 1999,
      Content:
        "Now maintenance of Ro water purifiers will be simple with just one plan",
      List1: "Maintenance/Breakdown protection/total damage prevention",
      List2:
        "Upon purchase, water purifiers are covered for a period of 10 years",
      List3: "Verified service experts",
      List4: "Guaranteed Visit within 6 hours",
    },
  ];
  const modelList = [
    {
      Name: "Chimney",
      ModImage: model1,
      Price: 1499,
      Content:
        "Free inspection, Fault based service, part cost extra/Free support on relocation with subscribed city",
      Freq: "2 times in a year",
    },
    {
      Name: "Fridge",
      ModImage: model2,
      Price: 999,
      Content: "Free inspection, Fault based service, part cost extra",
      Freq: "As and when reqired",
    },
    {
      Name: "Microwave",
      ModImage: model3,
      Price: 499,
      Content: "Free inspection, Fault based service, part cost extra",
      Freq: "As and when reqired",
    },
    {
      Name: "Smart TV",
      ModImage: model4,
      Price: 1299,
      Content: "Free inspection, Fault based service, part cost extra",
      Freq: "As and when reqired",
    },
  ];
  const serviceList = [
    {
      Name: "Buy",
      Content:
        "Now you can buy new refurbished gadgets and electronics with just one click. Check out the prices, compare them to your specs, and your new device is just a few clicks away!",
      Image: buy,
    },
    {
      Name: "Sell",
      Content:
        "You can sell your outdated electronics and gadgets for cash in minutes. Simply add the item to the WEFIXCARE listing and obtain the best offer from the comfort of your home or office.",
      Image: sell,
    },
    {
      Name: "Exchange",
      Content:
        "Have you ever thought about exchanging electronics or gadgets? Now it’s way more than easy to Get your old gadget exchanged with a new one in a snap.",
      Image: exchange,
    },
  ];
  return (
    <div className="wefixMain">
      <title>Book Online Electronic appliances Annual maintenance Service at the best price.</title>
      <div className="wefixIntro">
        <div>
          <h1>WEFIXCARE</h1>
          <h6>
            With WEFIXCARE, you can explore a comprehensive range of Annual
            Maintenance Charges Plan (AMC)
            <br />
            to refurbished products like Chimneys, Refrigerators, Water
            Purifiers and electronics can be found in <br />
            our inventory.
            <br />
            Under one roof, you can find everything from Amc Plans to new
            pre-owned items, plus a variety of options
            <br />
            for comparison so that you can select the best one for you.
          </h6>
        </div>
        <img src={mobile} alt="" />
      </div>
      <h2>Appliances</h2>
      <div className="appliances">
        {applianceList.map((item) => (
          <Appliancecard item={item} />
        ))}
      </div>
      <h2>WEFIXCARE Model</h2>
      <h5>
        We have a great collection of AMCs for kitchen appliances, household
        items, and gadgets, all with the most cost-effective plans. Our wide
        selection of AMC plans will assist you in making the best decision!
      </h5>
      <div className="models">
        {modelList.map((item) => (
          <Modelcard item={item} />
        ))}
      </div>
      <h1>Our Other Services</h1>
      <div className="otherservices">
        {serviceList.map((item) => (
          <Othersevicecard item={item} />
        ))}
      </div>
      <Servicecardcontainer />
    </div>
  );
}
