import React from 'react'

export default function CartItem({item}) {
    return (
        <div className="CartItem">
            <div><img src={item.image} alt="" /></div> 
            <div>
                <h6 style={{fontSize: "20px", fontWeight: "bold"}}>{item.name}</h6> <h6 style={{fontWeight: 'bold'}}> x{item.quantity}</h6>
                <h6>MainTainance</h6> <h6>₹{item.price}</h6>
                <h6>Repair</h6> <h6>₹{item.quantity * item.price}</h6>
            </div>
        </div>
    )
}
