import React, { useState,useContext, useEffect } from 'react';
import AddNewProduct from './AddNewProduct';
import './AdminHome.css';
import AdminSidebar from './AdminSidebar';
import MyProduct from './MyProduct';
import PageNotReady from './PageNotReady';
import { AuthContext } from '../Pages/Auth/AuthProvider';
export default function AdminHome() {
  const [pageActive, setPage] = useState(1);
  const context=useContext(AuthContext);
  const setIsadmin=context.value.setIsadmin;
  useEffect(()=>{
      setIsadmin(true);
  },[])
  return (
    <div className='adminHome'>
    
      <AdminSidebar pageActive={pageActive} setPage={setPage} />
      {pageActive === 1 && <PageNotReady />}
      {pageActive === 2 && <MyProduct />}
      {pageActive === 3 && <AddNewProduct />}
      {pageActive === 4 && <PageNotReady />}
    </div>
  );
}
