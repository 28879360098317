import React from 'react';
import './DashBar.css';
import SearchAdmin from '../assets/Images/SearchAdmin.png';
import avatarAdmin from '../assets/Images/avatarAdmin.png';

export default function DashBar({ pageName }) {
  return (
    <div className='dashBarMain'>
      <h6>
        Dashboard {'>'} {pageName}
      </h6>
      <section>
        <input type='text' placeholder='Search' />
        <img src={SearchAdmin} />
      </section>
      <img src={avatarAdmin} />
    </div>
  );
}
