import React from 'react'
import reqImage from '../assets/Images/reqCallback.png'
import call from '../assets/Images/Call.png'
import message from '../assets/Images/Message.png'
import name from '../assets/Images/Profile.png'

import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import arrow from '../assets/Images/Vector.png'

export default function Requestcallback({setCartprof}) {
    return (
        <div className="reqCallback">
            <div className="reqContainer">
                <div>
                    <img src={reqImage} alt="" />
                </div>
                <form>
                    <h1>Request a Callback</h1>
                    <div>
                        <img src={name} alt="" />
                        <input type="text" placeholder="Full Name"/>
                    </div>
                    <div>
                        <img src={call} alt="" />
                        <input type="number" placeholder="Phone Number"/>
                    </div>
                    <div>
                        <img src={message} alt="" />
                        <input type="text" placeholder="Email Address"/>
                    </div>
                    <section>
                        <input type="checkbox" />
                        <span>I agree to the terms and conditions</span>
                    </section>
                    <button type="submit">Get a Callback
                        <img src={arrow} alt="" />
                    </button>
                </form>
            </div>
            <HighlightOffIcon className="reqClose"  onClick={()=> setCartprof(0)} />
        </div>
    )
}
