import React from 'react'

import itemImage from '../assets/Images/Group 553.png'
import plusImage from '../assets/Images/Add.png'

export default function TrackYourServiceItem({refID, date1, date2, price1, price1Name, price2, price2Name}) {
    return (
        <div className="TYSitemContainer">
            <div><img src={itemImage} alt="" /></div>
            <div>
                <h2 style={{color: "#253D80"}}>Fridge</h2>
                <h6>Ref_id : {refID}</h6>
                <h6>Delivery Date : {date1}</h6>
                <h6>Bought on : {date2}</h6>
                <h6><img src={plusImage} style={{height: "3vh"}} /> Add a new service</h6>
            </div>
            <div>
                <h6>{price1Name}</h6> <h6>₹{price1}</h6>
                <h6>{price2Name}</h6> <h6>₹{price2}</h6>
                <h6 style={{fontSize: "3vh"}}>Total</h6> <h6 style={{fontSize: "3vh"}}>₹{price1 + price2}</h6>
            </div>
            <div>
                <h6><img src={plusImage} style={{height: "3vh"}} /> Download invoice</h6>
                <h6><img src={plusImage} style={{height: "3vh"}} /> Leave Feedback</h6>
            </div>
        </div>
    )
}
