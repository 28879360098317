import React, {  useState, useEffect } from 'react'
import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import arrow from '../assets/Images/Vector.png'
import washingmachine from '../assets/Images/Services/Washingmachine/Washingmachine.png'
import makeitbetter from '../assets/Images/Services/Washingmachine/makeitbetter.png'
import brandsweserve from '../assets/Images/Services/Washingmachine/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'


import repair from '../assets/Images/Services/Washingmachine/Group 434.png'
import annual from '../assets/Images/Services/Washingmachine/Group 446.png'
import install from '../assets/Images/Services/Washingmachine/Group 449.png'
import uninstall from '../assets/Images/Services/Washingmachine/Group 451.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'


export default function Airconditioner() {
    const [items, setItems] = useState([
        {name: 'Repair', content: "If you have a washer and want it repaired fast call UCRACKWEFIX. We repair all major brands and all problems under one roof! Our skilled service techs can solve your problem and have you up and running in no time.", image: repair, price: 299, quantity: 0},

        {name: 'Annual maintainance', content: 'The AMC Plan for a washing machine includes all benefits of the Extended Warranty Plan, as well as preventive maintenance (ex: chemical cleaning). Under our AMC, we will provide breakdown service and maintenance as needed, free of charge.', image: annual, price: 1999, quantity: 0},

        {name: 'Installation', content: 'Have you just purchased a new washing machine? Are you having trouble installing it? You are welcome to contact us to discuss your new washing machine installation needs. Our expert Pros are trained in all the latest appliances technologies and will be happy to help you', image: install, price: 299, quantity: 0},

        {name: 'Uninstallation', content: "When it comes to getting rid of or replacing your old washer and dryer, knowing how to remove them is crucial. Just give us a call and we'll be happy to assist you!", image: uninstall, price: 200, quantity: 0},
    ])

    
 
    
     const addtocart = (item,index, qty) =>{
         console.log('adding to cart', index, qty)
         var updatedItems = [...items]
         updatedItems[index].quantity = qty
         setItems(updatedItems)
         var cartlist = items.filter((item)=>item.quantity>0)
         localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
     }
    // console.log(items)
    return (
        <div className="serviceMain washingmachine">
            <title>India’s leading washing machine repair at doorstep & maintenance service</title>
            <div className="serviceHead">
                <img src={washingmachine} alt="" />
                <div>
                    <h1 className="heading">Washing Machine</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>Washing machines are essential home appliances that keep clothes clean and tidy without putting in too much effort. All your laundry activities will come to a halt if your washing machine breaks down. A blown fuse, faulty power socket, damaged start capacitor, broken motor, and blown main PCB could be the reasons why your washing machine won't start. The professionals at UCRACKWEFIX can diagnose and repair washing machines of all types including LG, Whirlpool, Samsung, Electrolux, Godrej, Videocon, Onida, Panasonic, and many more.</h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>Here are a few services we provide:</h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map(
                            (item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you.
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>The device and service can be chosen based on your preferences. In case you need help placing the order, just contact us at 9017-247-247 or fill in the form by telling us what service you want and click on the connect button for a callback.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>The washing machine will be repaired at your location. If there is a minor issue Our executive partner will repair it at that time. If any part is missing or needs to be repaired then Our expert technicians will diagnose and fix your device to perfection.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>Your device will be like new within 2-5 days depending upon the issue and the spare part availability.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
