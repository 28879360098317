import React, { useState, useEffect } from "react";
import { getAuth,onAuthStateChanged } from "@firebase/auth";
import "../../firebase/firebase";
export const AuthContext = React.createContext();
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [message, setMessage] = useState("");
  const [isadmin,setIsadmin]=useState(false);
  const [cu, setCu] = useState({
    user:''
   });
  useEffect(()=>{
    const auth=getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid =  user.uid;
           setCu(prevState => {
            return {
            
              user: user.uid
            };
          });
      
      
       
          // ...
        } else {
          // User is signed out
          // ...
        }
      });
    
 },[])
    const value = {
    cu,
    message,
    setMessage,
    isadmin,
    setIsadmin
  };

  return (
    <div>
      
      <AuthContext.Provider value={{ value }}>{children}</AuthContext.Provider>
    </div>
  );
};

export default AuthProvider;
