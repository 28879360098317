import React, { useState } from 'react';
import './ContactForm.css';
import logo from '../../assets/Images/logoicon.png';

export default function ContactForm() {
  const [cityOpen, setCityOpen] = useState(false);
  const [city, setCity] = useState(-1);
  const [modelOpen, setModelOpen] = useState(false);
  const [model, setModel] = useState(-1);
  const [brandOpen, setBrandOpen] = useState(false);
  const [brand, setBrand] = useState(-1);

  const [timeSlot, setTimeSlot] = useState('');

  const dummytimeslot = [
    'something',
    'something',
    'something',
    'something',
    'something',
    'something',
  ];
  const citynames = [
    'Delhi',
    'Chennai',
    'Kolkata',
    'Jaipur',
    'Mumbai',
    'Durgapur',
  ];
  return (
    <div className='ContactFormMain'>
      <h2
        style={{
          width: '100%',
          textAlign: 'left',
          padding: '2vh 4vw',
          fontWeight: 'bold',
        }}
      >
        Contact Form
      </h2>
      <form>
        <div className='contactForm'>
          <img src={logo} />
          <section>
            <input type='text' placeholder='Full name' />
            <input type='number' placeholder='Phone number' />
            <input type='text' placeholder='Email address' />
            <div
              onMouseEnter={() => setCityOpen(true)}
              onMouseLeave={() => setCityOpen(false)}
            >
              {city === -1 ? 'City' : citynames[city]}
              {cityOpen && (
                <article className='formOptions'>
                  {citynames.map((item, index) => (
                    <p
                      onMouseEnter={() => setCity(index)}
                      onClick={() => setCityOpen(false)}
                    >
                      {item}
                    </p>
                  ))}
                </article>
              )}
            </div>
            <div
              onMouseEnter={() => setBrandOpen(true)}
              onMouseLeave={() => setBrandOpen(false)}
            >
              {brand === -1 ? 'Device Brancd' : citynames[brand]}
              {brandOpen && (
                <article className='formOptions'>
                  {citynames.map((item, index) => (
                    <p
                      onMouseEnter={() => setBrand(index)}
                      onClick={() => setBrandOpen(false)}
                    >
                      {item}
                    </p>
                  ))}
                </article>
              )}
            </div>
            <div
              onMouseEnter={() => setModelOpen(true)}
              onMouseLeave={() => setModelOpen(false)}
            >
              {model === -1 ? 'Device Model' : citynames[model]}
              {modelOpen && (
                <article className='formOptions'>
                  {citynames.map((item, index) => (
                    <p
                      onMouseEnter={() => setModel(index)}
                      onClick={() => setModelOpen(false)}
                    >
                      {item}
                    </p>
                  ))}
                </article>
              )}
            </div>

            <div>Issues</div>
            <p style={{ textAlign: 'left', color: '#253d80' }}>Time-slot</p>
            <section className='timeslotContainer'>
              {dummytimeslot.map((item) => (
                <span onClick={() => setTimeSlot(item)} className='timeSlot'>
                  {item}
                </span>
              ))}
            </section>
          </section>
        </div>
        <div className='checkoutContainer'>
          <h3>Total amount</h3>
          <h4>
            <span>Amount</span> <span style={{ fontWeight: 'bold' }}>2000</span>
          </h4>
          <h4>
            <span>GST</span> <span style={{ fontWeight: 'bold' }}>120</span>
          </h4>
          <p
            style={{
              height: '2px',
              margin: '2vh 0',
              backgroundColor: '#00467F44',
            }}
          ></p>
          <h4>
            <span style={{ fontWeight: 'bold' }}>Amount</span>{' '}
            <span style={{ fontWeight: 'bold' }}>2120</span>
          </h4>
          <div>
            <input type='text' placeholder='Have a coupon?' />
            <button>Apply</button>
          </div>
          <button>Proceed to checkout</button>
        </div>
      </form>
    </div>
  );
}
