import React from "react";

const Dashboard = () => {
   
  return (<div>
      logged in
  </div>);
};

export default Dashboard;
