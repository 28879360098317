import React,{useState} from 'react'
import { Container, Grid, makeStyles, Typography,Paper,TextField } from '@material-ui/core'
import {  primaryLightColor,primaryColor,secondaryColor,whiteColor } from '../assets/constants/constants';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// images
import FormImage from "../assets/Images/formearthImage.png"

import ServiceImage1 from "../assets/Images/ContactFormIcons/icons blue/Group 484.png"
import ServiceImage2 from "../assets/Images/ContactFormIcons/icons blue/Group 485.png"
import ServiceImage3 from "../assets/Images/ContactFormIcons/icons blue/Group 486.png"
import ServiceImage4 from "../assets/Images/ContactFormIcons/icons blue/Group 487.png"
import ServiceImage5 from "../assets/Images/ContactFormIcons/icons blue/Group 488.png"
import ServiceImage6 from "../assets/Images/ContactFormIcons/icons blue/Group 489.png"
import ServiceImage7 from "../assets/Images/ContactFormIcons/icons blue/Group 490.png"
import ServiceImage8 from "../assets/Images/ContactFormIcons/icons blue/Group 491.png"

import ServiceSelectedImage1 from "../assets/Images/ContactFormIcons/icons orange/Group 326.png"
import ServiceSelectedImage2 from "../assets/Images/ContactFormIcons/icons orange/Group 327.png"
import ServiceSelectedImage3 from "../assets/Images/ContactFormIcons/icons orange/Group 328.png"
import ServiceSelectedImage4 from "../assets/Images/ContactFormIcons/icons orange/Group 329.png"
import ServiceSelectedImage5 from "../assets/Images/ContactFormIcons/icons orange/Group 333.png"
import ServiceSelectedImage6 from "../assets/Images/ContactFormIcons/icons orange/Group 330.png"
import ServiceSelectedImage7 from "../assets/Images/ContactFormIcons/icons orange/Group 331.png"
import ServiceSelectedImage8 from "../assets/Images/ContactFormIcons/icons orange/Group 332.png"



import Search1 from "../assets/Images/ContactFormIcons/search1.png"
import Search2 from "../assets/Images/ContactFormIcons/search2.png"

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        overflowX:'hidden'
    },

    title:{
        fontSize:35,
        fontWeight:800,
        margin:'40px 30px',
        textAlign:'start'
    },
    formPaper:{
        borderRadius:20
    },
    formdiv1:{
        backgroundColor:primaryLightColor,
        borderTopLeftRadius:20,
        borderBottomLeftRadius:20
    },
    formdiv2:{
        backgroundColor:"#E5E5E5",
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
            borderRadius:20,
            // borderBottomLeftRadius:20
        },
    },
    formtextfeild:{
        textAlign:'start',
        display:'flex',
        justifyContent:'flex-start',
        width:'90%',
        margin:"10px 20px",
        marginRight:'5%'
    },
    formdiv3:{
        backgroundColor:primaryLightColor,
        borderTopRightRadius:20,
        borderBottomRightRadius:20,
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        [theme.breakpoints.only('xs')]: {
            display: 'none'
        },
    },
    formcontainer:{
        display:'flex',
        justifyContent:'space-between',
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
        },
    },
    formdesc:{
        paddingTop:40,
        fontSize:20,
        fontWeight:600,
        width:'60%',
        [theme.breakpoints.only('xs')]: {
            fontSize:12
        },
        
    },
    formimage:{
        width:150,
        height:150,
        marginTop:40,
        [theme.breakpoints.only('xs')]: {
            width:50,
            height:50,
        },
    },
    buttonContainer:{
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:secondaryColor,
        width:200,
        borderRadius:20,
        margin:"10px 0px",
        cursor:'pointer',
        [theme.breakpoints.only('xs')]: {
            width:160,
        },
    },
    buttonContainerblog:{
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:primaryColor,
        width:200,
        borderRadius:20,
        margin:"10px 0px",
        cursor:'pointer',
        position:'absolute',
        bottom:20
    },
    buttonContainermain:{
        display:'flex',
        justifyContent:'center',
    },
    buttontext:{
        textAlign:'center',
        color:whiteColor,
        padding:10,
        fontSize:16,
        width:'90%'
    },
    arrowiconcontainer:{
        padding:6,
        backgroundColor:"#c7c5c5",
        borderTopRightRadius:20,
        borderBottomRightRadius:20,
        borderRadius:20

    },
    arrowicon:{
        color:'#f7f7f7',
        fontSize:30,
    },
    formtitle:{
        fontSize:18,
        margin:'20px',
        textAlign:'start'
    },
    radiogroup:{
        display:'flex',
        justifyContent:'flex-start',
        [theme.breakpoints.only('xs')]: {
           display:'flex',
           flexDirection:'column'
        },
        
    },
    radio: {
        '&₹checked': {
          color: primaryColor
        }
      },
      checked: {},
    servicecontainer:{
        display:'flex',
        margin:20,
        [theme.breakpoints.only('xs')]: {
            flexDirection:'column',
        },
    },
    serviceimage:{
        width:80,
        height:80,
        objectFit:'contain',
        margin:'10px 10px',
        cursor:'pointer',
    },
    servicetypecontainer:{
        border:'1px solid',
        borderColor:secondaryColor,
        borderRadius:8,
        padding:10,
        display:'flex',
        justifyContent:'space-between',
        width:150,
        marginLeft:20,
        cursor:'pointer',
        [theme.breakpoints.only('xs')]: {
           marginTop:10
        },
    },
    searchicon:{
        width:40,
        height:25,
        objectFit:'contain'
    }

  }));


export default function ContactUsPage() {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [service,setService] = React.useState("")
    const [fullName,setFullName] = useState("") 
    const [phone,setPhone] = useState("") 
    const [email,setEmail]= useState("")
    const [dob,setDob] = useState("")
    const [gender,setGender] = useState("")
    const [message,setMessage] = useState("")



    const handleChange = (event) => {
      setValue(event.target.value);
    };


    const handleSubmit = () => {
        console.log(fullName,phone,email,dob,gender,message,value)
    }
  

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>Contact Form</Typography>
                <Container>
                    <Paper className={classes.formPaper} >
                        <Grid container>
                            <Grid item sm={1} xs={0} className={classes.formdiv1} ></Grid>
                            <Grid item sm={7} xs={12} className={classes.formdiv2}>
                                <br />
                                <TextField className={classes.formtextfeild} id="standard-basic" label="FullName" onChange={(e)=>setFullName(e.target.value)} />
                                <TextField className={classes.formtextfeild} id="standard-basic" label="Phone Number" onChange={(e)=>setPhone(e.target.value)} />
                                <TextField className={classes.formtextfeild} id="standard-basic" label="Email Address" onChange={(e)=>setEmail(e.target.value)}  />
                                <div className={classes.formcontainer}>
                                <TextField className={classes.formtextfeild} id="standard-basic" label="Gender" onChange={(e)=>setGender(e.target.value)} />
                                <TextField className={classes.formtextfeild} id="standard-basic" label="Date Of Birth" onChange= {(e)=>setDob(e.target.value)} />
                                </div>     


                                <Typography className={classes.formtitle}>Choose from service</Typography>
                                <Grid container spacing={3} alignContent="center" alignItems="center">
                                    <Grid item xs={6} sm={3}>
                                        {/* <img src={ServiceImage1} alt="" className={classes.serviceimage} onClick={()=>setService("mobile")} /> */}
                                        {
                                            service !== 'mobile' ?
                                            <img src={ServiceImage1} alt="" className={classes.serviceimage} onClick={()=>setService("mobile")} />
                                            :
                                            <img src={ServiceSelectedImage1} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'tab' ?
                                            <img src={ServiceImage2} alt="" className={classes.serviceimage} onClick={()=>setService("tab")} />
                                            :
                                            <img src={ServiceSelectedImage2} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'laptop' ?
                                            <img src={ServiceImage3} alt="" className={classes.serviceimage} onClick={()=>setService("laptop")} />
                                            :
                                            <img src={ServiceSelectedImage3} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                    
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'lcd/led' ?
                                            <img src={ServiceImage4} alt="" className={classes.serviceimage} onClick={()=>setService("lcd/led")} />
                                            :
                                            <img src={ServiceSelectedImage4} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                   
                                         </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'cctv' ?
                                            <img src={ServiceImage5} alt="" className={classes.serviceimage} onClick={()=>setService("cctv")} />
                                            :
                                            <img src={ServiceSelectedImage5} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                   
                                         </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'ac' ?
                                            <img src={ServiceImage6} alt="" className={classes.serviceimage} onClick={()=>setService("ac")} />
                                            :
                                            <img src={ServiceSelectedImage6} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'waterfilter' ?
                                            <img src={ServiceImage7} alt="" className={classes.serviceimage} onClick={()=>setService("waterfilter")} />
                                            :
                                            <img src={ServiceSelectedImage7} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                    {
                                            service !== 'washingmachine' ?
                                            <img src={ServiceImage8} alt="" className={classes.serviceimage} onClick={()=>setService("washingmachine")} />
                                            :
                                            <img src={ServiceSelectedImage8} alt="" className={classes.serviceimage} onClick={()=>setService("")} />
                                        }                                    </Grid>

                                </Grid>


                                <div className={classes.radiogroup}>
                                <Typography className={classes.formtitle}>Is the device in warranty?</Typography>
                                <FormControl component="fieldset">
                                <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                                    <div style={{display:'flex',margin:10}}>
                                    <FormControlLabel value="yes" control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio classes={{root: classes.radio, checked: classes.checked}}/>} label="No" />
                                    </div>
                                </RadioGroup>
                                </FormControl>
                                </div>

                                <Typography className={classes.formtitle}>Is the device in warranty?</Typography>
                                <div className={classes.servicecontainer}>
                                    <div className={classes.servicetypecontainer}>
                                        <img src={Search1} alt="" className={classes.searchicon}/>
                                        <Typography className={classes.servicetypetext}>Inspection</Typography>
                                    </div>
                                    <div className={classes.servicetypecontainer}>
                                        <img src={Search2} alt="" className={classes.searchicon}/>
                                        <Typography className={classes.servicetypetext}>Inspection</Typography>
                                    </div>
                                </div>
                                <Typography className={classes.formtitle}>Message</Typography>
                                <TextField 
                                className={classes.formtextfeild} 
                                id="standard-basic" 
                                variant="outlined"
                                multiline={true}
                                rows={5}
                                onChange={(e)=>setMessage(e.target.value)}
                                />


                                <div className={classes.buttonContainer} style={{margin:20}} onClick={handleSubmit}>
                                <div className={classes.buttontext}>
                                    Connect
                                </div>
                                <div className={classes.arrowiconcontainer}>
                                    <ArrowRightAltIcon className={classes.arrowicon}/>
                                </div>
                        </div>

                            </Grid>
                            <Grid item sm={4} xs={3} className={classes.formdiv3}>
                                    <Typography className={classes.formdesc}>Kindly fill the details to connect to us</Typography>
                                    <img src={FormImage} alt="" className={classes.formimage} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
        </div>
    )
}
