import React, {  useState, useEffect } from 'react'
import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import mobile from '../assets/Images/Services/Mobilephones/mobile.png'
import arrow from '../assets/Images/Vector.png'
import makeitbetter from '../assets/Images/Services/Mobilephones/Makeitbetter.png'
import brandsweserve from '../assets/Images/Services/Mobilephones/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'


import Backpanelrepair from '../assets/Images/Services/Mobilephones/Back panel repair.png'
import Batteryreplacement from '../assets/Images/Services/Mobilephones/Battery replacement.png'
import Camerareplacement from '../assets/Images/Services/Mobilephones/camera replacement.png'
import Chargingjackreplacement from '../assets/Images/Services/Mobilephones/charging jack replacement.png'
import Mic from '../assets/Images/Services/Mobilephones/Mic.png'
import Receiver from '../assets/Images/Services/Mobilephones/Receiver.png'
import Screenrepair from '../assets/Images/Services/Mobilephones/Screen repair.png'
import Softwareissues from '../assets/Images/Services/Mobilephones/Software issues.png'
import Speaker from '../assets/Images/Services/Mobilephones/Speaker.png'
import Waterdamage from '../assets/Images/Services/Mobilephones/waterdamage.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'

export default function Tablet() {
    const [items, setItems] = useState([
        {name: 'Screen Repair', content: "Is it hard to enjoy your iPhone due to a damaged or cracked screen? Be calm! UCRACKWEFIX  offers the same-day repair of all types of phone screens." , image: Screenrepair, price: 1000, quantity: 0},

        {name: 'Charging jack replacement', content: "Our professionals can replace the charging jack for all phone models. With our online repair service, we deliver the best parts online! And we can quickly repair your phone's charging port.", image: Chargingjackreplacement , price: 1999, quantity: 0},

        {name: 'Water damage', content: "Water-damaged phones can be easily fixed by UCRACKWEFIX even if they have been dropped in water by mistake.", image: Waterdamage, price: 5000, quantity: 0},

        {name: 'Software issues', content: "Your phone has major and minor software problems. You can reach us at any time. Experience smoother performance on your phone with our service.", image: Softwareissues, price: 499, quantity: 0},

        {name: 'Speaker', content: "Do you have a loudspeaker that buzzes or doesn't work? To be able to enjoy jams again, we help you replace them easily and cost-effectively.", image: Speaker, price: 500, quantity: 0},

        {name: 'Back panel repair', content: "Do you have a broken glass or back panel on your phone? UcrackWeFix technicians can quickly and efficiently replace the back panel on any smartphone. All phones' back glass can be replaced at your location.", image: Backpanelrepair, price: 500, quantity: 0},

        {name: 'Battery Replacement', content: "Our mobile phone replacement services cover all manufacturers' batteries. We provide up to 6 months of warranty on all battery replacements by our experts.", image: Batteryreplacement, price: 1999, quantity: 0},

        {name: 'MIC', content: "Our same-day service will repair the microphone on almost any phone. We're known for our low prices and high-quality parts. Let our specialized technicians replace the mic on your mobile phone. Get it fixed now!", image: Mic, price: 500, quantity: 0},

        {name: 'Camera replacement', content: "Using photographs to capture memories is something we all enjoy doing. As a result, it's rather upsetting when your phone's camera stops working. You can get your Phone Camera fixed no matter where you are.", image: Camerareplacement, price: 1999, quantity: 0},
    ])
    const addtocart = (item,index, qty) =>{
        console.log('adding to cart', index, qty)
        var updatedItems = [...items]
        updatedItems[index].quantity = qty
        setItems(updatedItems)
        var cartlist = items.filter((item)=>item.quantity>0)
        localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
    }


    return (
        <div className="serviceMain mobilephone">
            <title>Online doorstep mobile repair service at the best prices ever</title>
            <div className="serviceHead">
                <img src={mobile} alt="" />
                <div>
                    <h1 className="heading" style={{fontSize: '6vh'}}>Mobile Phones</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>When it comes to mobile repair or mobile servicing, you can trust us to be professional. Whatever the problem with your mobile phone, we are here to fix it. We have a team of certified technicians who can help you if your phone screen is broken or your touch is not working. We offer doorstep service, so you don't have to waste time waiting in queues at stores.</h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>Mobile phones are integral to our daily lives these days, and the mere thought of our mobile phone malfunctioning is enough to create serious anxiety. A few of the most common problems that mobile phone users suffer include:
                </h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map((item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you.
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>You can select the device and service based on your preferences. If you need assistance placing your order, please call us at 9017-247-247 or fill out the form below by describing the service you require and pressing the connect button to receive a callback. </h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>Pick up of your Mobile phone is FREE. If there is a minor issue Our executive partner will repair it at your place or else will pick up your device At our Lab, Our expert technicians will diagnose and fix your device to perfection.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>We will deliver your repaired device within 2-5 days, depending on the location and repair type.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
