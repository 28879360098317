import React from 'react'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import arrow from '../../assets/Images/Vector.png'

export default function Applicationmodal({item, setAppModal}) {
    return (
        <div className="applicationmodal" onBlur={()=>setAppModal(false)}>
            <div className="appModalContainer">
                <div/>
                <div>
                    <h2>{item.Name}</h2>
                    <h6>{item.Content}</h6>
                    <h4>Appliances</h4>
                    <ul>
                        <li>
                            <h6>{item.List1}</h6>
                        </li>
                        <li>
                            <h6>{item.List2}</h6>
                        </li>
                        <li>
                            <h6>{item.List3}</h6>
                        </li>
                        <li>
                            <h6>{item.List4}</h6>
                        </li>
                    </ul>
                    <h2>₹{item.Price}</h2>
                    <div onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")}>Buy Now
                        <img src={arrow} alt="" />
                    </div> 
                </div>
                <div>
                    <img src={item.AppImage} alt="" />
                </div>
            </div>
            <HighlightOffIcon className="appClose"  onClick={()=> setAppModal(0)} />
        </div>
    )
}
