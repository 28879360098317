import React, {  useState, useEffect } from 'react'
import './Services.css'
import ServiceItem from '../Pages/ServiceItem'

import arrow from '../assets/Images/Vector.png'
import airconditioner from '../assets/Images/Services/Airconditioner/Airconditioner.png'
import makeitbetter from '../assets/Images/Services/Airconditioner/makeitbetter.png'
import brandsweserve from '../assets/Images/Services/Airconditioner/brands.png'
import process1 from '../assets/Images/Services/Frame 407.png'
import process2 from '../assets/Images/Services/Frame 406.png'
import process3 from '../assets/Images/Services/Frame 405.png'


import repair from '../assets/Images/Services/Airconditioner/Group 434.png'
import annual from '../assets/Images/Services/Airconditioner/Group 435.png'
import install from '../assets/Images/Services/Airconditioner/Group 442.png'
import uninstall from '../assets/Images/Services/Airconditioner/Group 439.png'
import Servicecardcontainer from '../Components/Servicecardcontainer'


export default function Airconditioner() {
    const [items, setItems] = useState([
        {name: 'Repair', content: "Choose this service To ensure that your air conditioner performs correctly, it is essential to schedule regular maintenance. Make sure your AC is serviced twice a year before and after the summer.", image: repair, price: 399, quantity: 0},

        {name: 'Annual maintainance', content: "AMC Plan offers all the benefits under the Extended Warranty plan, not only do we cover preventive services (ex: AC cleaning/ filter cleaning) but also consumables (gas charging, filter replacement, etc). Our AMC plans offer the best value for money by providing all of the care you need for your appliances.", image: annual, price: 1999, quantity: 0},

        {name: 'Installation', content: "A correctly installed AC will operate more efficiently and work as it was intended to. It will also minimize unwanted noise and vibration, as well as increase efficiency. You can always count on us for small repairs!", image: install, price: 499, quantity: 0},

        {name: 'Uninstallation', content: "Even though uninstalling can be challenging, our experts are well-versed in all aspects of it. The appliance will be examined before the uninstallation.", image: uninstall, price: 599, quantity: 0},
    ])

    
     const addtocart = (item,index, qty) =>{
         console.log('adding to cart', index, qty)
         var updatedItems = [...items]
         updatedItems[index].quantity = qty
         setItems(updatedItems)
         var cartlist = items.filter((item)=>item.quantity>0)
         localStorage.setItem("ucrackcart", JSON.stringify(cartlist) )
     }
    // console.log(items)
    return (
        <div className="serviceMain airconditioner">
            <title>looking for AC repair services? The best AC maintenance service near you</title>
            <div className="serviceHead">
                <img src={airconditioner} alt="" />
                <div>
                    <h1 className="heading">Air Conditioner</h1>
                    <h6>Quality repairing at your Affordability</h6>
                    <h6>Know the services</h6>
                    <h6>Brands we service</h6>
                    <h6>Know the Process</h6>
                    <button onClick={()=>window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")} className="RepairBTN" style={{marginTop: '7vh'}}><span>Repair my device</span><img src={arrow} alt="" /></button>
                </div>
            </div>
            <div className="makeitBetter">
                <div>
                    <h1 className="heading" style={{fontSize: "35px"}}>Customer Service, We Make it Better.</h1>
                    <h6>Modern-day life is incomplete without an air conditioner. This is often the only way to keep from going insane during the summer. However, keeping a complete room cool - and the people inside it - is hard, even for machines, especially in conditions where the outside temperature exceeds 45 Degree Celsius. AC's doesn't come cheap, so if you don't plan on buying a new one every year, getting your AC serviced by a professional at least once a year will ensure that it runs at great performance. Get your AC repaired by the best professionals today!
                        
                    </h6>
                </div>
                <div>
                    <img src={makeitbetter} alt="" />
                </div>
            </div>
            <div className="knowtheservices">
                <h1>Know the services</h1>
                <h6>Ucrackwefix provides instant access to highly skilled and company-trained professionals through its services, which include:</h6>
            </div>
                <div className="serviceItems">
                    {
                        items.map(
                            (item, index)=>(
                            <ServiceItem item={item} index={index} addtocart={addtocart}/>
                        ))
                    }
                </div>
            <div className="brandsweserve">
                <h1>Brands We Service</h1>
                <img src={brandsweserve} alt="" />
            </div>
            <div className="knowtheprocess">
                <h1>Know the Process</h1>
                <h6>Are you nervous about getting your gadgets or any electronic devices repaired? At UCRACKWEFIX, we understand! Our network of technicians is background checked. We have put together a process that allows us to make your repair fast and convenient for you
                </h6>
            </div>
            <div className="knowCards">
                <div className="blue"></div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process1} alt="" />
                    </div>
                    <h3>Book a Service</h3>
                    <h6>The device and service can be chosen based on your preferences. In case you need help placing the order, just contact us at 9017-247-247 or fill in the form by telling us what service you want and click on the connect button for a callback.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process2} alt="" />
                    </div>
                    <h3>Device Collection</h3>
                    <h6>Air Conditioner will be repaired at your location. If there is a minor issue Our executive partner will repair it at that time. If any part is missing or needs to be repaired then Our expert technicians will diagnose and fix your device to perfection.</h6>
                </div>
                <div className="processCard">
                    <div className="processImg">
                        <img src={process3} alt="" />
                    </div>
                    <h3>Repair done at doorstep.</h3>
                    <h6>Your device will be like new within 2-5 days depending upon the issue and the spare part availability.</h6>
                </div>
                
            </div>
            <Servicecardcontainer/>
        </div>
    )
}
