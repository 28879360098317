import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  Paper,
  Avatar,
} from '@material-ui/core';
import {
  primaryColor,
  primaryLightColor,
  secondaryColor,
  testcolor,
  whiteColor,
} from '../assets/constants/constants';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Carousel, Nav, NavLink } from 'react-bootstrap';

import CarouselCard from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Aos from 'aos';
import 'aos/dist/aos.css';

// images
import LandingMainImage from '../assets/Images/landingmainImage.png';
import ChooseUs1 from '../assets/Images/chooseus1.png';
import ChooseUs2 from '../assets/Images/chooseus2.png';
import ChooseUs3 from '../assets/Images/chooseus3.png';
import ChooseUs4 from '../assets/Images/chooseus4.png';
import ChooseUs5 from '../assets/Images/chooseus5.png';
import ChooseUs6 from '../assets/Images/ewaste.png';

import BlogImage1 from '../assets/Images/BlogImages/blog1.jpeg';
import BlogImage2 from '../assets/Images/BlogImages/blog2.jpg';
import BlogImage3 from '../assets/Images/BlogImages/blog3.jpg';
import BlogImage4 from '../assets/Images/BlogImages/blog4.jpg';
import BlogImage5 from '../assets/Images/BlogImages/blog5.jpg';
import BlogImage6 from '../assets/Images/BlogImages/blog6.jpg';
import UserReview from '../assets/Images/userreview.png';
import Serviceimage1 from '../assets/Images/service1.png';
import Serviceimage2 from '../assets/Images/service2.png';
import Serviceimage3 from '../assets/Images/service3.png';
import Serviceimage4 from '../assets/Images/service4.png';
import Serviceimage5 from '../assets/Images/service5.png';
import Serviceimage6 from '../assets/Images/service6.png';
import Serviceimage7 from '../assets/Images/service7.png';
import Serviceimage8 from '../assets/Images/service8.png';

import buy from '../assets/Images/Wefixcare/buy.png';
import sell from '../assets/Images/Wefixcare/sell.png';
import exchange from '../assets/Images/Wefixcare/exchange.png';

import ServiceCardBackroundImage from '../assets/Images/servicecardbackground.png';
import KnowUsImage from '../assets/Images/Group 492.png';
import HomepageCarousal from './HomepageCarousal';
import Othersevicecard from './wefixcare/Othersevicecard';
import './wefixcare/Wefixcare.css';

const shadowColorHex = 'rgba(0, 0, 0, 0.52)';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    overflow: 'hidden',
  },
  landingbackround: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Lato',

    height: 650,
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
    zIndex: '20 !important',
  },
  landingbackroundleft: {
    backgroundColor: primaryLightColor,
    width: '50%',
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '50%',
    },
  },
  landingbackroundright: {
    backgroundColor: `rgb(${testcolor})`,
    width: '50%',
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
      height: '50%',
    },
  },
  landingcenterimage: {
    position: 'absolute',
    top: '10%',
    display: 'flex',
    fontFamily: 'Lato',
    justifyContent: 'center',
    [theme.breakpoints.only('xs')]: {
      top: '35%',
      width: '100%',
    },
    zIndex: '20 !important',
  },
  mainimage: {
    width: 300,
    height: 500,
    objectFit: 'contain',
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      width: 200,
      height: 150,
      marginTop: 40,
    },
  },
  lefttitle: {
    fontSize: 50,
    fontWeight: 800,
    marginTop: 200,
    textAlign: 'start',
    paddingLeft: 100,
    color: secondaryColor,
    fontFamily: 'Lato',
    letterSpacing: 2,
    [theme.breakpoints.only('xs')]: {
      fontSize: 25,
      fontWeight: 600,
      marginTop: 100,
      paddingLeft: 0,
      textAlign: 'center',
      color: secondaryColor,
      letterSpacing: 2,
    },
  },
  leftsubtitle: {
    fontSize: 20,
    fontWeight: 400,
    marginTop: 30,
    paddingLeft: 100,
    color: secondaryColor,
    letterSpacing: 0.4,
    width: '70%',
    fontFamily: 'Lato',
    textAlign: 'justify',
    [theme.breakpoints.only('xs')]: {
      fontSize: 16,
      fontWeight: 400,
      marginTop: 2,
      marginBottom: 20,
      textAlign: 'center',
      paddingLeft: 0,
      color: secondaryColor,
      letterSpacing: 0.4,
      width: '90%',
      marginLeft: '5%',
    },
  },
  righttitle: {
    fontSize: 30,
    fontWeight: 500,
    marginTop: 250,
    textAlign: 'start',
    paddingLeft: 100,
    color: secondaryColor,
    letterSpacing: 0.4,
    marginLeft: 100,
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      fontSize: 25,
      fontWeight: 600,
      marginTop: 200,
      paddingLeft: 0,
      textAlign: 'center',
      color: secondaryColor,
      letterSpacing: 2,
      marginLeft: 0,
    },
  },
  rightsubtitle: {
    width: '70%',
    fontSize: 20,
    fontWeight: 400,
    textAlign: 'start',
    paddingLeft: 100,
    fontFamily: 'Lato',
    color: secondaryColor,
    letterSpacing: 0.4,
    marginLeft: 100,
    marginTop: 20,
    [theme.breakpoints.only('xs')]: {
      width: '90%',
      fontSize: 20,
      fontWeight: 400,
      marginTop: 20,
      textAlign: 'center',
      paddingLeft: 0,
      color: secondaryColor,
      letterSpacing: 0.4,
      marginLeft: '5%',
    },
  },
  title: {
    fontSize: 35,
    fontWeight: 800,
    margin: '40px 0px',
    fontFamily: 'Lato',
  },
  normaltext: {
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Lato',
  },
  buttonContainer: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'backgroundColor': secondaryColor,
    'width': 200,
    'borderRadius': 20,
    'margin': '10px 0px',
    'fontFamily': 'Lato',
    'cursor': 'pointer',
    'transition': '0.5s',
    'boxShadow': `2px 5px 10px ${shadowColorHex}`,
    '&:hover': {
      color: '#ffffff',
      boxShadow: `5px 10px 15px ${shadowColorHex}`,
    },
  },
  buttonContainerblog: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'backgroundColor': primaryColor,
    'width': 200,
    'borderRadius': 20,
    'fontFamily': 'Lato',
    'margin': '10px 0px',
    'cursor': 'pointer',
    'position': 'absolute',
    'bottom': 20,
    'transition': '0.5s ease',
    'boxShadow': `2px 5px 10px ${shadowColorHex}`,
    '&:hover': {
      color: '#fff',
      boxShadow: `5px 10px 15px ${shadowColorHex}`,
    },
  },
  buttonContainermain: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Lato',
  },
  buttontext: {
    textAlign: 'center',
    color: whiteColor,
    padding: 10,
    fontSize: 16,
    width: '90%',
    transition: '0.5s',
    fontFamily: 'Lato',
  },
  arrowiconcontainer: {
    padding: 6,
    backgroundColor: '#c7c5c5',
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    borderRadius: 20,
    transition: '0.5s ease',
    fontFamily: 'Lato',
  },
  arrowicon: {
    color: '#f7f7f7',
    fontSize: 30,
    fontFamily: 'Lato',
  },
  carosuelnext: {
    marginLeft: 50,
    fontSize: 40,
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
    },
    zIndex: '20 !important',
  },
  carosuelprev: {
    marginRight: 80,
    fontSize: 40,
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      marginLeft: 0,
    },
  },
  chooseusimage: {
    height: 300,
    width: '100%',
    objectFit: 'contain',
    fontFamily: 'Lato',
  },
  chooseusimageModified: {
    height: 230,
    width: '100%',
    objectFit: 'contain',
    fontFamily: 'Lato',
  },
  chooseustitle: {
    fontSize: 18,
    color: secondaryColor,
    fontWeight: 800,
    letterSpacing: 1,
    fontFamily: 'Lato',
  },
  chooseusdesc: {
    fontSize: 16,
    color: secondaryColor,
    letterSpacing: 1,
    fontFamily: 'Lato',
  },
  servcetitle: {
    textAlign: 'center',
    color: secondaryColor,
    fontWeight: 600,
    paddingTop: 80,
    fontFamily: 'Lato',
  },
  serviceimagecontainer: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Lato',
    maxWeight: '60px',
  },
  serviceimage: {
    height: 100,
    width: 120,
    position: 'absolute',
  },
  servicecard: {
    marginTop: 50,
    borderRadius: 40,
    backgroundImage: `url(${ServiceCardBackroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '300px',
    boxShadow: `2px 5px 10px {shadowColorHex}`,
    cursor: 'pointer',
    fontFamily: 'Lato',
  },
  servicedesc: {
    color: secondaryColor,
    fontSize: 14,
    height: '21vh',
    padding: '10px 20px',
    fontWeight: 500,
    fontFamily: 'Lato',
    overflow: 'hidden',
  },
  servicearrowbackround: {
    'backgroundColor': primaryLightColor,
    'borderRadius': '50%',
    'padding': 10,
    'marginBottom': 20,
    'cursor': 'pointer',
    'fontFamily': 'Lato',
    '&:hover': {
      padding: 12,
    },
  },
  blogcard: {
    margin: '50px 0px',
    borderRadius: 30,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '400px',
    width: '90%',
    fontFamily: 'Lato',

    marginLeft: '5%',
  },
  blogimage: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    fontFamily: 'Lato',
  },
  blogtitle: {
    fontSize: 20,
    fontWeight: 600,
    padding: '20px 20px',
    textAlign: 'start',
    fontFamily: 'Lato',
  },
  blogdesc: {
    maxHeight: 80,
    textAlign: 'left',
    marginLeft: 30,
    marginRight: 30,
    lineHeight: '15px',
  },
  blogtime: {
    fontSize: 12,
    padding: '20px 20px',
    textAlign: 'start',
    color: '#8c8c8c',
    fontWeight: 600,
    fontFamily: 'Lato',
  },
  userreviewdiv: {
    display: 'flex',
    justifyContent: 'center',
    fontFamily: 'Lato',
  },
  userreviewimage: {
    height: 400,
    width: '100%',
    objectFit: 'contain',
    margin: '30px 0px',
    fontFamily: 'Lato',
    [theme.breakpoints.only('xs')]: {
      height: 200,
    },
  },
  reviewcard: {
    margin: '50px 0px',
    borderRadius: 30,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '90%',
    height: '34vh',
    marginLeft: '5%',
    fontFamily: 'Lato',
  },
  reviewcardheader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: 20,
    fontFamily: 'Lato',
  },
  reviewavatar: {
    marginRight: 20,
    fontFamily: 'Lato',
  },
  reviewname: {
    fontSize: 20,
    fontWeight: 600,
    color: secondaryColor,
    paddingTop: 4,
    fontFamily: 'Lato',
  },
  reviewtitle: {
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Lato',
    padding: '10px 30px',
    textAlign: 'start',
  },
  review: {
    fontSize: 14,
    fontWeight: 500,
    padding: '5px 30px',
    fontFamily: 'Lato',
    textAlign: 'justify',
  },
  formPaper: {
    borderRadius: 20,
    fontFamily: 'Lato',
  },
  formdiv1: {
    backgroundColor: primaryLightColor,
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    fontFamily: 'Lato',
  },
  formdiv2: {
    backgroundColor: '#E5E5E5',
    fontFamily: 'Lato',
  },
  formtextfeild: {
    textAlign: 'start',
    fontFamily: 'Lato',
    display: 'flex',
    justifyContent: 'flex-start',
    width: '80%',
    margin: '10px 20px',
  },
  formdiv3: {
    backgroundColor: primaryLightColor,
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
    fontFamily: 'Lato',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formcontainer: {
    display: 'flex',
    fontFamily: 'Lato',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexDirection: 'column',
    },
  },
  formdesc: {
    paddingTop: 40,
    fontSize: 20,
    fontFamily: 'Lato',
    fontWeight: 600,
    width: '60%',
  },
  formimage: {
    width: 150,
    height: 150,
    marginTop: 40,
    fontFamily: 'Lato',
  },
  sellimage: {
    width: '100%',
    height: 300,
    fontFamily: 'Lato',
    objectFit: 'contain',
  },
}));

export default function Homepage() {
  const classes = useStyles();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  });

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 566, min: 0 },
      items: 1,
    },
  };
  const serviceList = [
    {
      Name: 'Buy',
      Content:
        'Now you can buy new refurbished gadgets and electronics with just one click. Check out the prices, compare them to your specs, and your new device is just a few clicks away!',
      Image: buy,
      Link: '/refurbished-phones',
    },
    {
      Name: 'Sell',
      Content:
        'You can sell your outdated electronics and gadgets for cash in minutes. Simply add the item to the WEFIXCARE listing and obtain the best offer from the comfort of your home or office.',
      Image: sell,
    },
    {
      Name: 'Exchange',
      Content:
        'Have you ever thought about exchanging electronics or gadgets? Now it’s way more than easy to Get your old gadget exchanged with a new one in a snap.',
      Image: exchange,
    },
  ];

  return (
    <div className={classes.root}>
      <title>Best Online Electronic Repair service, fastest electronics repair at doorstep</title>
      <HomepageCarousal />

      <Typography className={classes.title}>Repair Services</Typography>
      <Container style={{ marginBottom: '80px' }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/mobile-repair'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage1}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink href='/mobile-repair' className={classes.servicecard}>
              <Typography>
                <h4 className={classes.servcetitle}>Mobile Phones</h4>
                <Typography className={classes.servicedesc}>
                  Mobile phones are great but when they start to break down they
                  can be irritating. This is where our service comes in handy.
                  We repair all brands of smartphones at your doorstep.
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/tablet-repair-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage2}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink
              href='/tablet-repair-service'
              className={classes.servicecard}
            >
              <Typography>
                <h4 className={classes.servcetitle}>Tablet/Ipad</h4>
                <Typography className={classes.servicedesc}>
                  Own a tablet or iPad? had any accidents with it? Fortunately,
                  UCRACKWEFIX ensures your gadget is in great shape even after
                  repair, by using top-quality parts and improving all damages.{' '}
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/laptop-repair-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage3}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink
              href='/laptop-repair-service'
              className={classes.servicecard}
            >
              <Typography>
                <h4 className={classes.servcetitle}>Laptop</h4>
                <Typography className={classes.servicedesc}>
                  Having laptops these days is a necessity. Our laptop repair
                  team consists of passionate and dedicated laptop professionals
                  who will provide you with the best laptop repair experience.{' '}
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/tv-repair-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage4}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink href='/tv-repair-service' className={classes.servicecard}>
              <Typography>
                <h4 className={classes.servcetitle}>LED/LCD</h4>
                <Typography className={classes.servicedesc}>
                  If you wish to repair your LED/LCDs that are sensitive to
                  electrical components. The best way to prevent this is to call
                  an expert, We offer doorstep and affordable services for all
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/water-purifier-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage5}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink
              href='/water-purifier-service'
              className={classes.servicecard}
            >
              <Typography>
                <h4 className={classes.servcetitle}>RO & Water Filters</h4>
                <Typography className={classes.servicedesc}>
                  The water purifier is a complex machine that needs expert and
                  highly trained service engineers. We repair RO & Water Filters
                  at your doorstep with affordable services.{' '}
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/ac-repair-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage6}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink href='/ac-repair-service' className={classes.servicecard}>
              <Typography>
                <h4 className={classes.servcetitle}>AC</h4>
                <Typography className={classes.servicedesc}>
                  Air condition breakdown or malfunction or need servicing
                  UCRACKWEFIX ensures it is fixed on the same day and at your
                  convenience.{' '}
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/washing-machine-repair'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage7}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink
              href='/washing-machine-repair'
              className={classes.servicecard}
            >
              <Typography>
                <h4 className={classes.servcetitle}>Washing Machine</h4>
                <Typography className={classes.servicedesc}>
                  We will help you solve your washing machine issues immediately
                  so that you don't have to fret. Experts at UCRACKWEFIX handle
                  all issues with ease and at an affordable price.{' '}
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper className={classes.servicearrowbackround}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            style={{ margin: '20px 0px' }}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <NavLink href='/cctv-installation-service'>
              <div className={classes.serviceimagecontainer}>
                <img
                  src={Serviceimage8}
                  alt=''
                  className={classes.serviceimage}
                />
              </div>
            </NavLink>
            <NavLink
              href='/cctv-installation-service'
              className={classes.servicecard}
            >
              <Typography>
                <h4 className={classes.servcetitle}>CCTV</h4>
                <Typography className={classes.servicedesc}>
                  Issues with the CCTV camera include fuzzy pictures and
                  low-quality images, connector problems, not working at all,
                  and many more.
                </Typography>
              </Typography>
              <div className={classes.serviceimagecontainer}>
                <Paper
                  className={classes.servicearrowbackround}
                  onClick={() =>
                    window.open('https://e4h3uqkvd80.typeform.com/to/gCyzpvLw')
                  }
                >
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </Paper>
              </div>
            </NavLink>
          </Grid>
        </Grid>
      </Container>

      {/* <Typography className={classes.title}>Buy/Sell</Typography>
                <Container>
                <Grid container spacing={3} className={classes.sectioncontainer}>
                    <Grid item xs={12} sm={6}>
                        <img src={BuyImage} alt ="" className={classes.sellimage}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.normaltext}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget adipiscing turpis auctor est egestas faucibus adipiscing odio lobortis. Tincidunt elementum mus morbi sed neque ligula purus. Elit at quam sem mi dolor porttitor. Ultrices elementum lacus, condimentum morbi accumsan nullam pellentesque. Auctor sit ut venenatis ut vel pellentesque egestas sapien libero.
                        <br></br><br></br>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget adipiscing turpis auctor est egestas faucibus adipiscing odio lobortis. Tincidunt elementum mus morbi sed neque ligula purus. Elit at quam sem mi dolor porttitor. Ultrices elementum lacus, condimentum morbi accumsan nullam pellentesque. Auctor sit ut venenatis ut vel pellentesque egestas sapien libero.       
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <div className={classes.buttontext}>
                                Take A look
                            </div>
                            <div className={classes.arrowiconcontainer}>
                            <ArrowRightAltIcon className={classes.arrowicon}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
                </Container> */}

      <Typography
        data-aos='fade-up'
        data-aos-duration='4000'
        className={classes.title}
      >
        Why Choose Us?
      </Typography>
      <Container style={{ marginBottom: '80px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <div className='Image1'></div>
            <img src={ChooseUs1} alt='' className={classes.chooseusimage} />
            <Typography className={classes.chooseustitle}>
              Same Day Service
            </Typography>
            <Typography className={classes.chooseusdesc}>
              {' '}
              When your phone breaks, you don’t want it fixed tomorrow—you need
              it fixed today.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <img src={ChooseUs2} alt='' className={classes.chooseusimage} />
            <Typography className={classes.chooseustitle}>
              Best Price Guarantee{' '}
            </Typography>
            <Typography className={classes.chooseusdesc}>
              Repairing beats replacing, and UCRACKWEFIX beats all other phone
              repair companies.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <img src={ChooseUs3} alt='' className={classes.chooseusimage} />
            <Typography className={classes.chooseustitle}>
              Superior Warranty{' '}
            </Typography>
            <Typography className={classes.chooseusdesc}>
              Stronger than any phone case, UCRACKWEFIX offers the most reliable
              warranty in the market.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <img
              src={ChooseUs4}
              alt=''
              className={classes.chooseusimageModified}
            />
            <Typography className={classes.chooseustitle}>
              Professional Technician
            </Typography>
            <Typography className={classes.chooseusdesc}>
              Our technicians are experts in the field of handling all the
              complexities of repairing damaged components.{' '}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <img
              src={ChooseUs5}
              alt=''
              className={classes.chooseusimageModified}
            />
            <Typography className={classes.chooseustitle}>
              Certified Parts
            </Typography>
            <Typography className={classes.chooseusdesc}>
              Using authorized parts is important to us, to maintain high
              service standards and quality in all our repairs.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} data-aos='fade-up' data-aos-duration='4000'>
            <img
              src={ChooseUs6}
              alt=''
              className={classes.chooseusimageModified}
            />
            <Typography className={classes.chooseustitle}>
              E-waste managements
            </Typography>
            <Typography className={classes.chooseusdesc}>
              UCRACKWEFIX ensures that e-waste is channeled to the registered
              recycler where it is transported and disposed of in a safe manner.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Typography
        data-aos='fade-up'
        data-aos-duration='4000'
        className={classes.title}
      >
        Get to know Us!
      </Typography>
      <Container style={{ marginBottom: '80px' }}>
        <Grid container spacing={3} className={classes.sectioncontainer}>
          <Grid item xs={12} sm={6} data-aos='fade-up' data-aos-duration='4000'>
            <Typography className={classes.normaltext}>
              UcrackweFix is an e-commerce platform where we provide repair
              services for all electronic devices and appliances. With us, you
              are covered for extended warranties, AMC plans, and on-demand
              repair services. We also cover a wide range of services in one
              single plan.
              <br />
              <br />
              Our services are based on Home and life-based customized to fit
              the needs of each customer. Using technology and domain
              intelligence, the platform aims to understand the requirements of
              its user in particular before connecting them with our in-house
              experts.
              <br />
              <br />
              With over 4+ years of experience, We aim to repair quickly by
              using high-quality parts, and we also ensure that our procedures
              your service online - from updating your service repair meet the
              standard. In addition, you can control everything about
              information to tracking the progress of your service calls.
              <br />
              <br />
              Additionally, we provide you with useful information and tools,
              preventative maintenance tips, and information about product
              service.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} data-aos='fade-up' data-aos-duration='4000'>
            <img src={KnowUsImage} alt='' className={classes.sellimage} />
          </Grid>
        </Grid>
      </Container>

      <div className='otherservices'>
        {serviceList.map((item) => (
          <Othersevicecard item={item} />
        ))}
      </div>

      <Typography className={classes.title}>Blogs & Articles</Typography>
      <Container style={{ marginBottom: '80px' }}>
        <CarouselCard
          responsive={responsive}
          autoPlaySpeed={3000}
          arrows={true}
          infinite={true}
          autoPlay={true}
          style={{ margin: '20px 0px' }}
        >
          <Paper className={classes.blogcard}>
            <img src={BlogImage1} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              Repair and Installation of Air Conditioners in Delhi NCR by
              UCRACKWEFIX
            </Typography>
            <Typography className={classes.blogdesc}>
              {' '}
              In the 21st century, air conditioners are the most essential
              appliance at home,
            </Typography>

            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog1' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.blogcard}>
            <img src={BlogImage2} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              LED TVs: Common Problems To Be Aware Of
            </Typography>
            <Typography className={classes.blogdesc}>
              Indian homes have become increasingly equipped with televisions.
            </Typography>
            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog2' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.blogcard}>
            <img src={BlogImage3} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              Mobile Repairs At Your Doorstep
            </Typography>
            <Typography className={classes.blogdesc}>
              Does your mobile phone have a cracked screen? Are you concerned
            </Typography>
            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog3' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.blogcard}>
            <img src={BlogImage4} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              The Best Way To Service Your RO Water System
            </Typography>
            <Typography className={classes.blogdesc}>
              The availability of clean drinking water is crucial to health.
              Most of the cities{' '}
            </Typography>
            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog4' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.blogcard}>
            <img src={BlogImage5} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              The Best On-Demand Washing Machine Repair Service Provider
            </Typography>
            <Typography className={classes.blogdesc}>
              The task of washing clothes is one of the most tedious, and if you
              have kids who attend school
            </Typography>
            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog5' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
          <Paper className={classes.blogcard}>
            <img src={BlogImage6} alt='' className={classes.blogimage} />
            <Typography className={classes.blogtitle}>
              Looking for LED TV Repairing Technicians in Delhi NCR?
            </Typography>
            <Typography className={classes.blogdesc}>
              In a world where electronic gadget manufacturers and purchasers
              are growing immensely,
            </Typography>
            <Typography className={classes.blogtime}> </Typography>
            <div className={classes.serviceimagecontainer}>
              <div className={classes.buttonContainerblog}>
                <Nav.Link href='/blog4' className={classes.buttontext}>
                  Take A look
                </Nav.Link>
                <div className={classes.arrowiconcontainer}>
                  <ArrowRightAltIcon className={classes.arrowicon} />
                </div>
              </div>
            </div>
          </Paper>
        </CarouselCard>
      </Container>

      <Typography
        data-aos='fade-up'
        data-aos-duration='4000'
        className={classes.title}
      >
        User Reviews
      </Typography>

      <div className={classes.userreviewdiv}>
        <img src={UserReview} alt='' className={classes.userreviewimage} />
      </div>

      <Container>
        <CarouselCard
          responsive={responsive}
          autoPlaySpeed={3000}
          arrows={true}
          infinite={true}
          autoPlay={true}
          style={{ margin: '20px 0px' }}
        >
          <Paper
            className={classes.reviewcard}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <div className={classes.reviewcardheader}>
              <Avatar
                className={classes.reviewavatar}
                style={{ backgroundColor: primaryColor }}
              >
                A
              </Avatar>
              <Typography className={classes.reviewname}>Ajay Yadav</Typography>
            </div>
            <Typography className={classes.review}>
              The friendly staff and reliable service definitely make it an
              ideal place to get any gadget or home appliance repair.{' '}
            </Typography>
          </Paper>
          <Paper
            className={classes.reviewcard}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <div className={classes.reviewcardheader}>
              <Avatar
                className={classes.reviewavatar}
                style={{ backgroundColor: secondaryColor }}
              >
                A
              </Avatar>
              <Typography className={classes.reviewname}>
                Kanam Sharma
              </Typography>
            </div>
            <Typography className={classes.review}>
              My laptop had a screen crack and with UCRCAKWEFIX I got it
              repaired on time with genuine quality screen replacement. Thanks
              to the technician who was very friendly and professional.
            </Typography>
          </Paper>
          <Paper
            className={classes.reviewcard}
            data-aos='fade-up'
            data-aos-duration='4000'
          >
            <div className={classes.reviewcardheader}>
              <Avatar
                className={classes.reviewavatar}
                style={{ backgroundColor: primaryColor }}
              >
                A
              </Avatar>
              <Typography className={classes.reviewname}>
                Shanaya Arora
              </Typography>
            </div>
            <Typography className={classes.review}>
              With quality service and genuine parts, my household products are
              fixed like new for a great price. Would highly recommend
              UCRCAKWEFIX.{' '}
            </Typography>
          </Paper>
        </CarouselCard>
      </Container>
      {/* <FooterContactForm title="Connect With Us !"/> */}
    </div>
  );
}
