import React from 'react'
import phoneimg from "../assets/Images/Track1.png"
import vector from '../assets/Images/Vector.png'

export default function TrackYourServiceLogin({setLoggedIn, setPhone, getorder}) {

    const getinfo = () =>{
        setLoggedIn(1)
        getorder()
    }
    return (
        <div className="TYScontainer">
                <div className="form">
                    <h2>Track Your Service</h2>
                    {/* <input type="text" placeholder="Email Address"/>
                    <h6>or</h6>
                    <input type="text" placeholder="Phone number" onChange={(e)=>setPhone(e.target.value)}/>
                    <h6>or</h6> */}
                    <input type="text" placeholder="Order Id" />
                    <br/>
                    <button type="submit" onClick={() => getinfo()} >Tract Status <img src={vector} alt="" /> </button>
                </div>
                <div className="forminfo">
                    <p>
                    Kindly fill in yourref_id to track your service
                    </p>
                    <img src={phoneimg} alt="" />
                </div>
        </div>
    )
}
