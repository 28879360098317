import React, {useState} from 'react'
import BottomArrow from '../assets/Images/Services/BottomArrow.png'
import arrow from '../assets/Images/Vector.png'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

export default function ServiceItem({item, index , addtocart}) {
    const [contentOpen, setContentOpen] = useState(false)
    const [itemCount, setItemCount] = useState(item.quantity)
    
    return (
        <div className="serviceItem">
            <img src={item.image} alt="" />
            <h4>{item.name}</h4>
            <h5>
                Read more
                {/* Price : <span>₹{item.price}</span>  */}
                <img src={BottomArrow} alt=" " onClick={()=> setContentOpen(!contentOpen)} className={"bottomBTN "+(contentOpen && "bottomBTNActive")}/>
            </h5>
            <h6 className={"content "+(contentOpen && "contentActive")}>
                {item.content}
            </h6>
            {itemCount === 0?
                <button className="RepairBTN" onClick={()=>{
                    window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")
                    // addtocart(item, index, itemCount+1)
                    // setItemCount(itemCount+1)
                }}><span>Book Now</span><img src={arrow} alt="" /></button>
            :
                <div className="itemCount">
                    <RemoveCircleOutlineIcon onClick={()=>{
                         addtocart(item,index, itemCount-1)
                        setItemCount(itemCount-1)
                        }} />
                    <div>{itemCount}</div>
                    <AddCircleOutlineIcon onClick={()=>{
                        addtocart(item,index, itemCount+1)
                        setItemCount(itemCount+1)
                        }}/>
                </div>
            }
        </div>
    )
}
