import React from 'react'
import './ThankYou.css'
import thankyoupic from '../assets/Images/ThankYou.png'

export default function ThankYou() {
    return (
        <div className="thankyouMain">
            <div className="thankyoucontainer">
                {/* <h4>Payment Successful</h4> */}
                <img src={thankyoupic} alt="" />
                <h1>Thank You</h1>
            </div>

        </div>
    )
}
