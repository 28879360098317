import { getAuth, RecaptchaVerifier,createUserWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, addDoc, getDocs, updateDoc, setDoc, doc, getDoc,deleteDoc } from "firebase/firestore";
import { firestore } from "../../firebase/firebase";
const auth = getAuth();
auth.languageCode = 'it';
const createuser = (name,email,phone,setUsercreated,setMessage) => {
    const password="notrequired";
    createUserWithEmailAndPassword(auth, email, password)
    .then(() => {
        saveuserdata({name,email,phone});
        setUsercreated(true);
        setMessage("Signed up successfully, now you can login");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
}

const saveuserdata = async (values) => {
    try {
      const user = await auth.currentUser
      const id = user.uid;
      const docRef = await setDoc(doc(firestore, 'document', id), values);
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  
  
  }

// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

export { createuser ,saveuserdata}