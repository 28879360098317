import React from 'react'
import CarouselCard from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import Servicecard from './Servicecard'
import './Servicecard.css'

import service1 from "../assets/Images/Services/servicecarouselimage1.png"
import service2 from "../assets/Images/Services/servicecarouselimage2.png"

export default function Servicecardcontainer() {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    const servicesList = [
        {Name: "Washing Machine", image: service1 , detail1: "Repair", detail2: "Annual Maintainace", detail3: "Installation", detail4: "Uninstallations", route:"/washingmachine"},
        // {Name: "Laptop", image: service2 , detail1: "Motherboard", detail2: "Battery replacement", detail3: "Screen repair", detail4: ""},
        {Name: "Laptop", image: service2 , detail1: "Motherboard", detail2: "Battery replacement", detail3: "Screen repair", detail4: "Heating Issue",route:"/laptop"},
        {Name: "LCE/LED", image: service1 , detail1: "Repair", detail2: "Annual Maintainace", detail3: "Installation", detail4: "Uninstallations",route:"/LCD"},
        // {Name: "Laptop", image: service2 , detail1: "Motherboard", detail2: "Battery replacement", detail3: "Screen repair", detail4: ""},
    ]
    return (
      <div className="servicecardMain">
        <CarouselCard

        responsive={responsive}
        autoPlaySpeed={3000}
        arrows={true}
        infinite={true}
        autoPlay={true}
        style={{margin:"20px 0px"}}
        >
              
              {
                  servicesList.map((item)=> <Servicecard item={item} /> )
              }
        </CarouselCard>
      </div>
    )
}
