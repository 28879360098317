import React from "react";
import { NavLink } from "react-bootstrap";

import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import service1 from "../assets/Images/service1.png";
import service2 from "../assets/Images/service2.png";
import service3 from "../assets/Images/service3.png";
import service4 from "../assets/Images/service4.png";
import service5 from "../assets/Images/service5.png";
import service6 from "../assets/Images/service6.png";
import service7 from "../assets/Images/service7.png";
import service8 from "../assets/Images/service8.png";

export default function Repairservices({ setCartprof }) {
  return (
    <div className="repairMain">
      <div className="repairservices">
        <NavLink href="/mobile-repair" className="repaircard">
          <h2>Mobile Phone</h2>
          <img src={service1} alt="" />
        </NavLink>
        <NavLink href="/water-purifier-service" className="repaircard">
          <h2>Water Purifier</h2>
          <img src={service5} alt="" />
        </NavLink>
        <NavLink href="/tablet-repair-service" className="repaircard">
          <h2>Tablet</h2>
          <img src={service2} alt="" />
        </NavLink>
        <NavLink href="/ac-repair-service" className="repaircard">
          <h2>AC</h2>
          <img src={service6} alt="" />
        </NavLink>
        <NavLink href="/laptop-repair-service" className="repaircard">
          <h2>Laptop</h2>
          <img src={service3} alt="" />
        </NavLink>
        <NavLink href="/washing-machine-repair" className="repaircard">
          <h2>Washing Machine</h2>
          <img src={service7} alt="" />
        </NavLink>
        <NavLink href="/tv-repair-service" className="repaircard">
          <h2>LCD/LED</h2>
          <img src={service4} alt="" />
        </NavLink>
        <NavLink href="/cctv-installation-service" className="repaircard">
          <h2>CCTV</h2>
          <img src={service8} alt="" />
        </NavLink>
      </div>
      <HighlightOffIcon className="closeBTN" onClick={() => setCartprof(0)} />
    </div>
  );
}
