import React from 'react';
import DashBar from './DashBar';
import './MyProduct.css';

export default function MyProduct() {
  return (
    <div className='myProductsMain'>
      <DashBar pageName={'My Products'} />
    </div>
  );
}
