import React, { useState, useEffect } from "react";
import profile from "../assets/Images/Profile.png";
import AMC from "../assets/Images/AMC.png";
import purchase from "../assets/Images/Purchase.png";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Nav, NavLink } from "react-bootstrap";

export default function Profile({ setCartprof }) {
  const [user, setUser] = useState(null);
  useEffect(() => {
    let userLocal = localStorage.getItem("ucrackuser");
    console.log('userLocal', userLocal)
    setUser(JSON.parse(userLocal));
  }, []);

  const logout = () => {
    console.log("loggin out ....");
    localStorage.removeItem("ucrackuser");
    setUser(null);
  };
  return (
    <div className="profileMain">
        <div onClick={() => setCartprof(0)}>
          <HighlightOffIcon className="close2" />
        </div>
      <div className="profileContainer">
        <div>
          <img src={profile} />
          <NavLink href="/login">
            {user == null ? "Login/Sign Up" : user.name}
          </NavLink>
        </div>
        <div>
          <img src={purchase} />
          <NavLink href="/purchase">Purchase History</NavLink>
        </div>
        {user != null ? (
          <div>
            <img src={AMC} />
            <span onClick={() => logout()}>Logout</span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
