import React from 'react'

import dot from "../assets/Images/Services/orangedot.png"
import arrow from '../assets/Images/Vector.png'
import { Nav,NavLink } from 'react-bootstrap';
 
export default function Servicecard({item}) {
    return (
        <div className="serviceCard">
            <div/>
            <div>
                <h2 style={{fontSize: "3vh"}}>{item.Name}</h2>
                <h6>We Serve:</h6>
                <h5><img src={dot} alt="" /> {item.detail1}</h5>
                <h5><img src={dot} alt="" /> {item.detail2}</h5>
                <h5><img src={dot} alt="" /> {item.detail3}</h5>
                <h5><img src={dot} alt="" /> {item.detail4}</h5>
                <NavLink href={item.route} className="viewservices2">
                View Service
                <img src={arrow} alt="" />
                </NavLink>
            </div>
            <div><img src={item.image} alt="" /></div>
        </div>
    )
}
