import React, { useState, useEffect } from "react";
import "./Services.css";
import ServiceItem from "../Pages/ServiceItem";

import arrow from "../assets/Images/Vector.png";
import tablet from "../assets/Images/Services/CCTV/CCTV.png";
import makeitbetter from "../assets/Images/Services/CCTV/makeitbetter.png";
import brandsweserve from "../assets/Images/Services/CCTV/brands.png";
import process1 from "../assets/Images/Services/Frame 407.png";
import process2 from "../assets/Images/Services/Frame 406.png";
import process3 from "../assets/Images/Services/Frame 405.png";

import repair from "../assets/Images/Services/CCTV/Group 434.png";
import annual from "../assets/Images/Services/CCTV/Group 459.png";
import install from "../assets/Images/Services/CCTV/Group 455.png";
import uninstall from "../assets/Images/Services/CCTV/Group 460.png";
import Servicecardcontainer from "../Components/Servicecardcontainer";

export default function CCTV() {
  const [items, setItems] = useState([
    {
      name: "Repair",
      content:
        "We offer high-quality CCTV repairs that address all of your security concerns and challenges. Our security specialists can repair and operate your CCTV camera, delivering high-quality service at an affordable price.",
      image: repair,
      price: 1099,
      quantity: 0,
    },

    {
      name: "Annual Maintainance",
      content:
        "The CCTV AMC Plan includes all the benefits available under the Extended Warranty Plan, as well as regular preventive services. For all your CCTV needs, we offer the service as and when required.",
      image: annual,
      price: 1999,
      quantity: 0,
    },

    {
      name: "Installation",
      content:
        "In CCTV, Signals Are Monitored For Surveillance And Security Purposes. Here, Signals Are Directed From One Location To Another Through A Closed-circuit System and we Install All Major Brands Of CCTV.",
      image: install,
      price: 12000,
      quantity: 0,
    },

    {
      name: "Uninstallation",
      content:
        "Whether you are moving out of the house or replacing them, you should remove your CCTV system. We provide hassle-free services We're only a phone call away whenever you need us!",
      image: uninstall,
      price: 5000,
      quantity: 0,
    },
  ]);

  const addtocart = (item, index, qty) => {
    console.log("adding to cart", index, qty);
    var updatedItems = [...items];
    updatedItems[index].quantity = qty;
    setItems(updatedItems);
    var cartlist = items.filter((item) => item.quantity > 0);
    localStorage.setItem("ucrackcart", JSON.stringify(cartlist));
  };
  // console.log(items)
  return (
    <div className="serviceMain CCTV">
      <title>Perfect choice for CCTV installation at home or anywhere. Best deals on CCTV setup price</title>
      <div className="serviceHead">
        <img src={tablet} alt="" />
        <div>
          <h1 className="heading">CCTV</h1>
          <h6>Quality repairing at your Affordability</h6>
          <h6>Know the services</h6>
          <h6>Brands we service</h6>
          <h6>Know the Process</h6>
          <button
            onClick={() =>
              window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")
            }
            className="RepairBTN"
            style={{ marginTop: "7vh" }}
          >
            <span>Repair my device</span>
            <img src={arrow} alt="" />
          </button>
        </div>
      </div>
      <div className="makeitBetter">
        <div>
          <h1 className="heading" style={{ fontSize: "35px" }}>
            Customer Service, We Make it Better.
          </h1>
          <h6>
            Closed-circuit television cameras, or CCTV cameras, are the most
            widely used surveillance device in the world. In recent years, the
            use of CCTV systems in the home and office security systems has
            expanded. It has been widely employed by technopaths, police, and
            military personnel because of its security benefits. <br />
            <br />
            UCRACKWEFIX recognizes that providing security is a very important
            procedure, so we provide skilled technicians to install various
            surveillance systems in order to ensure the highest level of
            security for our customers. At UCRACKWEFIX, we have a well-trained
            support staff. Our experts will assist you in swiftly and easily
            setting up, operating, and repairing your security system.
          </h6>
        </div>
        <div>
          <img src={makeitbetter} alt="" />
        </div>
      </div>
      <div className="knowtheservices">
        <h1>Know the services</h1>
        <h6>
          The common problems with CCTV cameras include low-quality images and
          fuzzy pictures. Therefore, UCRACKWEFIX caters to the following needs
          besides repairing CCTV cameras:
        </h6>
      </div>
      <div className="serviceItems">
        {items.map((item, index) => (
          <ServiceItem item={item} index={index} addtocart={addtocart} />
        ))}
      </div>
      <div className="brandsweserve">
        <h1>Brands We Service</h1>
        <img src={brandsweserve} alt="" />
      </div>
      <div className="knowtheprocess">
        <h1>Know the Process</h1>
        <h6>
          Are you nervous about getting your gadgets or any electronic devices
          repaired? At UCRACKWEFIX, we understand! Our network of technicians is
          background checked. We have put together a process that allows us to
          make your repair fast and convenient for you
        </h6>
      </div>
      <div className="knowCards">
        <div className="blue"></div>
        <div className="processCard">
          <div className="processImg">
            <img src={process1} alt="" />
          </div>
          <h3>Book a Service</h3>
          <h6>
            The device and service can be chosen based on your preferences. In
            case you need help placing the order, just contact us at
            9017-247-247 or fill in the form by telling us what service you want
            and click on the connect button for a callback.
          </h6>
        </div>
        <div className="processCard">
          <div className="processImg">
            <img src={process2} alt="" />
          </div>
          <h3>Device Collection</h3>
          <h6>
            CCTV will be repaired at your location. If there is a minor issue
            Our executive partner will repair it at that time. If any part is
            missing or needs to be repaired then Our expert technicians will
            diagnose and fix your device to perfection.
          </h6>
        </div>
        <div className="processCard">
          <div className="processImg">
            <img src={process3} alt="" />
          </div>
          <h3>Repair done at doorstep.</h3>
          <h6>
            Your device will be like new within 2-5 days depending upon the
            issue and the spare part availability.
          </h6>
        </div>
      </div>
      <Servicecardcontainer />
    </div>
  );
}
