import { getAuth, RecaptchaVerifier,createUserWithEmailAndPassword,setPersistence,inMemoryPersistence,signInWithPhoneNumber } from "firebase/auth";
import { collection, query, where, addDoc, getDocs, updateDoc, setDoc, doc, getDoc,deleteDoc } from "firebase/firestore";
import { Firebase } from "../../firebase/firebase";
const auth = getAuth();
auth.languageCode = 'it';
// const loadcaptchaverifier=()=>{
//     window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
//         'size': 'invisible',
//         'callback': (response) => {
//           signInWithPhoneNumber(auth, phoneNumber, appVerifier)
//           .then((confirmationResult) => {
//             // SMS sent. Prompt user to type the code from the message, then sign the
//             // user in with confirmationResult.confirm(code).
//             window.confirmationResult = confirmationResult;
//             // ...
//           }).catch((error) => {
//             // Error; SMS not sent
//             // ...
//           });
         
//         }
//       }, auth);
// }
const loginuser=(mynumber,setMessage,setLogin,setCodesent,setFinal)=>{
  
  window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
    'size': 'invisible',
    'callback': (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
   
    }
  }, auth);
  const appVerifier = window.recaptchaVerifier;
  
signInWithPhoneNumber(auth, mynumber, appVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      // window.confirmationResult = confirmationResult;
      setCodesent(true);
      setFinal(confirmationResult);
      setMessage("confimation code sent successfully")
      // ...
    }).catch((error) => {
      console.log(mynumber)
      console.log(error)
      // Error; SMS not sent
      // ...
    });
}
const ValidateOtp = (otp,final,setLogin) => {
 
  
    final.confirm(otp).then((result) => {
      // User signed in successfully.
      const user = result.user;
    
      console.log();
      window.location.replace('/dashboard')
     
      // ...
    }).catch((error) => {
      console.log(error);
      // User couldn't sign in (bad verification code?)
      // ...
    })
 
}
// Validate OTP


// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

export {  loginuser,ValidateOtp}