import React from 'react'

export default function PurchaseItem({item}) {
    return (
        <div className="purchaseItem">
            <h6>{item.Name}</h6>
            <h6>₹{item.Price}</h6>
        </div>
    )
}
