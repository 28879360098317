import React from "react";
import Logo from "../assets/Images/logoicon.png";
import "./Footer.css";

import fb from "../assets/Images/SocialIcons/Facebook.png";
import insta from "../assets/Images/SocialIcons/Instagram.png";
import twitter from "../assets/Images/SocialIcons/Twitter.png";
import yt from "../assets/Images/SocialIcons/Youtube.png";

import arrow from "../assets/Images/Arrow 1.png";

import { Nav } from "react-bootstrap";

export default function Footer() {
  return (
    <div className="FooterContainer">
      <div>
        <div>
          <img src={Logo} />
          <h3>Ucrackwefix</h3>{" "}
        </div>
        <h6>
          The ultimate goal for our company is to provide our customers with
          unparalleled service repair experiences. Repairs are not just about
          fixing – it's more about making your device like new again.
        </h6>
        <div>
       
          <a href="#" onClick={()=>{
            window.open("https://www.facebook.com/ucrackwefix/")
          }}>
            <img src={fb} />
          </a>
          <a onClick={()=>{
            window.open("https://instagram.com/official.ucrackwefix?utm_medium=copy_link")
          }} href="#">
            <img src={insta} />
          </a>
         
        </div>
      </div>
      <div>
        <Nav.Link
          onClick={() =>
            window.open("https://e4h3uqkvd80.typeform.com/to/gCyzpvLw")
          }
        >
          <h4>Repair services</h4>
        </Nav.Link>
        <Nav.Link href="/mobile-repair">
          <h4>Mobile Phones</h4>
        </Nav.Link>
        <Nav.Link href="/tablet-repair-service">
          <h4>Tablet</h4>
        </Nav.Link>
        <Nav.Link href="/ac-repair-service">
          <h4>AC</h4>
        </Nav.Link>
        <Nav.Link href="/laptop-repair-service">
          <h4>Laptop</h4>
        </Nav.Link>
      </div>
      <div>
        <Nav.Link>
          <h4>Important Links</h4>
        </Nav.Link>
        <Nav.Link href="/privacy-policy">
          <h4>Privacy Policy</h4>
        </Nav.Link>
        <Nav.Link href="/terms-and-conditions">
          <h4>Terms and Conditions</h4>
        </Nav.Link>
        <Nav.Link href="/warranty-policy">
          <h4>Warranties</h4>
        </Nav.Link>
        <Nav.Link
          onClick={() =>
            window.open("https://e4h3uqkvd80.typeform.com/to/S7dQ699k")
          }
        >
          <h4>Report a Complaint</h4>
        </Nav.Link>

        {/* <h2>Join Our Newsletter</h2>
                <div>
                    <input type="text"/>
                    <button><img src={arrow} /></button>
                </div> */}
        <h6>© 2021 All rights reserved</h6>
      </div>
    </div>
  );
}
