import React, { useContext,useEffect, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { Route, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { getAuth,onAuthStateChanged } from "@firebase/auth";
import { CircularProgress } from "@material-ui/core";
const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const context = useContext(AuthContext);
  const history = useHistory();
  const setMessage = context.value.setMessage;
  const [cu,setCu]=useState("");
  const cu1=context.value.cu;
  const [timeot,setTimeot]=useState(false);
  const sleep=()=>{
    setTimeout(() => {
      setTimeot(true);
    }, 2000);
  }
  useEffect(()=>{
    sleep();
    setCu(cu1);
    console.log(cu1);
  },[cu1])
  // Do this!


 
  
  
   
   
 
  return (
   timeot?( <Route
      {...rest}
      render={() =>
        !!(cu1.user ) ? (
          <RouteComponent {...rest} />
        ) : (
          (
           setMessage("Please login first"),
          
          (<Redirect to="/login" />))
        )
      }
    />):(<div style={{marginTop:"300px",marginBottom:"300px"}}><CircularProgress /></div>)
  );
};

export default PrivateRoute;
