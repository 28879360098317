import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core';
import axios from 'axios'

import TrackYourServiceLogin from "../Components/TrackYourServiceLogin"
import TrackYourServiceItem from "../Components/TrackYourServiceItem"

const useStyles = makeStyles((theme) => ({
    root: {
        flex:1,
        overflow:'hidden',
        height: 'max-content'
    },
    blueCircle: {
        background: "blue",
        height: "580px",
        width: "580px",
        // position: 'absolute'
    },
    orangeCircle: {
        background: "red",
        height: "580px",
        width: "580px",
        // position: 'absolute',
        transform: 'translate(100%, 100%)'
    }    
  }));

export default function TrackYourService() {
    const [loggedIn, setLoggedIn] = useState(0)
    const [phone, setPhone] = useState(0)
    const classes = useStyles()
    
    console.log(phone)
    const getorder = () => {
        console.log("calling the function")
        axios.post('https://us-central1-ucrackwefix-f8a89.cloudfunctions.net/get_my_orders', {
            "phone": "8200608176",
        })
    .then(response => console.log(response.data)).catch(error=>console.log(error))
    }

    return (
        <div className={classes.root}>
           <title>Track Your Service</title> 
            <div className="mainContainer">
                {loggedIn===1 ? 
                <>
                <TrackYourServiceItem refID={1001001001} date1={"25th sept 2021"} date2={"26th sept 2021"} price1={1200} price1Name={"Maintainance"} price2={1200} price2Name={"Repair"} />
                <TrackYourServiceItem refID={1001001001} date1={"25th sept 2021"} date2={"26th sept 2021"} price1={1200} price1Name={"Maintainance"} price2={1200} price2Name={"Repair"} />
                </>
                :
                <TrackYourServiceLogin setLoggedIn={setLoggedIn} setPhone={setPhone} getorder={getorder}/> 
                }
            </div>
        </div>
    )
}
